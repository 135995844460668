import { SettingsComponent } from '../../angular/src/app/settings/settings.component';
import { SecondaryNavbarComponent } from '../../angular/src/app/settings/secondary-navbar/secondary-navbar.component';
import {downgradeComponent} from '@angular/upgrade/static';   

export default angular.module('SettingsApp', [
    'KhBase',
    'TeamsApp'
]);

angular.module('SettingsApp')
.directive(
    'appSettings',
    downgradeComponent({component: SettingsComponent})
)
.directive(
    'appSecondaryNavbar',
    downgradeComponent({component: SecondaryNavbarComponent, outputs: [ 'addTeamClick' ]})
);