(function (angular) {
    'use strict';
    angular.module('TrialApp')
        .factory('TrialAppHelper', function TrialAppHelper($mdDialog, $filter) {

            function __get_readable_date_formats(success_data, date_keys) {
                var isArray = Array.isArray(success_data);
                if (!isArray) success_data = [success_data];

                for (var i = 0; i < success_data.length; i++) {
                    for (var j = 0; j < date_keys.length; j++) {
                        success_data[i]['readable_' + date_keys[j]] = _getReadableDate(success_data[i][date_keys[j]]);
                    }
                }
                if (!isArray) return success_data[0];
                return success_data;
            }

            function _getFilterDict(key, filterText, traslateKey, select) {
                var name = filterText;
                if (traslateKey) name = traslateKey;
                var dict = {};
                var id = 'text';
                if (select) id = select;
                dict[filterText] = {
                    id: id,
                    placeholder: $filter('translate')('common.filterBy') + $filter('translate')(key + '.' + name + '').toLowerCase()
                };
                return dict;
            }

            function _updateObjectInList(object_list, key_to_match, updated_object, fn) {
                for (var i = 0; i < object_list.length; i++) {
                    if (object_list[i][key_to_match] == updated_object[key_to_match]) {
                        object_list[i] = updated_object;
                        if (fn) fn();
                        return true;
                    }
                }
            }

            function _getMdDialog($mdDialog, $scope, dict) {
                var path = '/static/assets/khapps/trial/partials/includes/trial_dialog.html';
                if (dict && dict.path) path = dict.path;
                $mdDialog.show({
                    animation: true,
                    skipHide: true,
                    multiple: true,
                    templateUrl: path,
                    escapeToClose: false,
                    scope: $scope,
                    preserveScope: true
                }).then(function (success_data) {
                    if (dict && dict.fn) dict.fn();
                },
                function (error_data) {
                    if (dict && dict.fn) dict.fn();
                });
            }

            function _getMdDialogForm($mdDialog, $scope, dict) {
                var path = '/static/assets/khapps/trial/partials/includes/trial_stepwizard_dialog.html';
                if (dict && dict.path) path = dict.path;
                var mdDict = {
                    animation: true,
                    templateUrl: path,
                    escapeToClose: false,
                    scope: $scope,
                    preserveScope: true
                };
                if (dict && dict.mdDict) mdDict = dict.mdDict;
                if (dict && dict.controller) mdDict.controller = dict.controller;
                if (dict && dict.slug) mdDict.locals = dict.slug;
                $mdDialog.show(mdDict).then(function (success_data) {
                    $scope.$broadcast('responseSaved', success_data, dict);
                }, function (success_data) {
                    $scope.$broadcast('responseSaved', success_data, dict);
                });
            }

            function _currentReadableDate() {
                return moment().format('DD MMMM YYYY');
            }

            function _getReadableDate(timestamp, existing_format) {
                var date_format;
                if (existing_format) {
                    date_format = existing_format;
                } else {
                    date_format = 'YYYY-MM-DDT00:00:00';
                }
                return moment(timestamp, date_format).format('DD MMMM YYYY');
            }

            return {
                getMdDialog: _getMdDialog,
                getFilterDict: _getFilterDict,
                getMdDialogForm: _getMdDialogForm,
                updateObjectInList: _updateObjectInList,
                currentReadableDate: _currentReadableDate,
                getReadableDateFormats: __get_readable_date_formats,
            };

        });


})(angular);
