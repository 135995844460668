/* eslint-disable angular/controller-as */
angular.module("SettingsApp").controller('CreateTeamController', ['$scope', '$rootScope', 'ListOfAllSubscriptionPackagesFactory', 'ListOfAllApplicationsFactory',
    '$mdDialog', 'TeamsFactory', '$khMessage', 'TeamsRegionsFactory', 'DjangoSitesFactory', 'DefaultSiteApplicationsFactory', 'DefaultSiteSubscriptionPackagesFactory',
    function ($scope, $rootScope, ListOfAllSubscriptionPackagesFactory, ListOfAllApplicationsFactory, $mdDialog, TeamsFactory,
        $khMessage, TeamsRegionsFactory, DjangoSitesFactory, DefaultSiteApplicationsFactory, DefaultSiteSubscriptionPackagesFactory) {
        // eslint-disable-next-line no-undef
        $scope.permissions = permissions;

        function load_data() {
            $scope.data = {
                all_applications: ListOfAllApplicationsFactory.query(),
                sites: DjangoSitesFactory.query(),
                all_subscription_packages: ListOfAllSubscriptionPackagesFactory.query(),
                regions: TeamsRegionsFactory.query()
            };
        }

        $scope.team = {};
        $scope.team.list_of_applications = [];
        $scope.team.subscription_package = [];
        var default_apps_loaded = false;
        var default_subscription_packages_loaded = false;

        DefaultSiteApplicationsFactory.get({}, function (result) {
            for (var i = 0; i < result.data.length; i++) {
                $scope.team.list_of_applications.push(result.data[i]['app']);
            }
            default_apps_loaded = true;
            load_form();
        });

        DefaultSiteSubscriptionPackagesFactory.get({}, function (result) {
            for (var i = 0; i < result.data.length; i++) {
                $scope.team.subscription_package.push(result.data[i]['subscription_package']);
            }
            default_subscription_packages_loaded = true;
            load_form();
        });


        function load_form() {
            if (default_apps_loaded && default_subscription_packages_loaded) {
                setUpForm();
            }
        }

        $scope.createTeam = function (team) {
            TeamsFactory.create(team, function (team) {
                $khMessage.show('Team has been created successfully.');
                $mdDialog.hide();
                $rootScope.$broadcast('team_updated');
            }, function (err) {
                $khMessage.show(err.data.team_name[0], 3000);
            });
        };

        function setUpForm() {
            $scope.create_team_form = [
                {
                    'className': 'flex-100',
                    'key': 'team_name',
                    'type': 'input',
                    'templateOptions': {
                        'label': 'Team name',
                        required: true
                    }
                },
                {
                    'className': 'flex-100',
                    type: 'searchable_select',
                    key: 'list_of_applications',
                    templateOptions: {
                        required: true,
                        label: 'List of applications',
                        multiple: true,
                        labelProp: 'app_name',
                        valueProp: 'id',
                        options: $scope.data.all_applications
                    }
                },
                {
                    'className': 'flex-100',
                    type: 'searchable_select',
                    key: 'regions',
                    templateOptions: {
                        required: true,
                        label: 'Groups',
                        multiple: true,
                        labelProp: 'name',
                        valueProp: 'id',
                        options: $scope.data.regions
                    }
                },
                {
                    'className': 'flex-100',
                    type: 'searchable_select',
                    key: 'subscription_package',
                    templateOptions: {
                        required: true,
                        label: 'Subscription packages',
                        multiple: true,
                        labelProp: 'name',
                        valueProp: 'id',
                        options: $scope.data.all_subscription_packages
                    }
                }
            ];
            if ($scope.permissions.is_superuser) {
                $scope.create_team_form.push({
                    'className': 'flex-100',
                    type: 'searchable_select',
                    key: 'site',
                    templateOptions: {
                        required: true,
                        label: 'Site',
                        labelProp: 'name',
                        valueProp: 'id',
                        options: $scope.data.sites
                    }
                });
            }
        }

        load_data();
    }]);