angular.module("TrialApp").controller("ReportStatusChangeController",
    function ($location, $scope, $rootScope, $routeParams, ArticlesMetaSetupFactory,
              SubscriptionPackageFactory, ArticlesFactory, ArticlesReportFactory,
              TeamUsersFactory, TeamsListFactory, $filter, $mdDialog, fileUploads, $khMessage,
              RegionsFactory, TrialAppHelper, ArticleUtilsService, CachingUtilsService) {

        var article_slug;
        if ($routeParams.article_slug) {
            article_slug = $routeParams.article_slug;
        }
        else {
            article_slug = $rootScope.report_slug;
        }

        $scope.imageuploadsconfig = {
            show_images: true,
            app_tag: 'Articles',
            max_files: 1,
            db_type: 'mysql',
            display_type: 'detailed',
            allow_duplicates: true
        };
        $scope.meta = {
            teamusers: CachingUtilsService.addFactoryWithoutCache(TeamUsersFactory),
            teams_list: CachingUtilsService.addFactoryWithoutCache(TeamsListFactory),
            team_regions: CachingUtilsService.addFactoryWithoutCache(RegionsFactory),
            subscription_packages: CachingUtilsService.addFactoryWithoutCache(SubscriptionPackageFactory),
            categories: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, "categories", "article_categories"),
            commodities: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, "commodities", "article_commodities"),
            tags: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, "tags", "article_tags"),
            content_type: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, "content-type", "article_content_type"),
            article_type: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, "article-type", "article_article_type")
        };

        $scope.loadFactories = function () {
            ArticlesFactory.get({slug: article_slug, type: 'edit'}, function (success_data) {
                $scope.article = ApplicationHelpers.filterArticleEditData(success_data.data);
                $scope.article_type_fk = $scope.article.article_type_fk_readable;
                $scope.current_readable_date = TrialAppHelper.currentReadableDate();
                initiateSwitch();
                if ($routeParams.s == "approve") {
                    ApplicationHelpers.canPublishMessage()
                }
                ApplicationHelpers.parseArticleContent();
                ArticleUtilsService.ArticleHelpers().disable_editable_divs();
            }, function (error_data) {
                $location.path("/report");
            });

        };
        $scope.loadFactories();

        function initiateSwitch() {
            $scope.status = false;
            if ($scope.article.status == 2) {
                $scope.status = true;
            }
        }

        $scope.changeReportStatus = function (status) {
            $scope.article.status = 1;
            if (status == true) {
                $scope.article.status = 2
            }
        };

        $scope.saveReportStatus = function () {
            ApplicationHelpers.updateArticle();
        };

        $scope.cancelReport = function () {
            $mdDialog.hide()
        };

        $scope.get_client_icon = function () {
            if (client_name == 'niab') {
                return '/static/assets/khapps2/base/khutils/images/articles/niabtag.png';
            }
            else {
                return 'favicon.ico';
            }
        };

        $scope.can_publish = function () {
            if ($scope.article.approvers) {
                return ($scope.article.approvers.indexOf(user_id) != -1);
            }
            else {
                return true;
            }
        };

        var ApplicationHelpers = {
            filterArticleEditData: function (success_data) {
                success_data.publish_date = new Date(success_data.publish_date);

                fileUploads.displayExistingImages(success_data.featured_image, $scope.imageuploadsconfig["db_type"]);
                return success_data;
            },

            updateArticle: function () {
                // $scope.article.featured_image = fileUploads.saveImages();
                // $khMessage.show($filter('translate')('report.message.save.info'), 10000);
                ArticlesFactory.update($scope.article, function (success_data) {
                    $khMessage.show($filter('translate')('report.message.update.success'), 10000);
                    $mdDialog.hide(success_data.data);
                }, function (error) {
                    $khMessage.show($filter('translate')('report.message.update.error'), 10000);

                });
            },
            canPublishMessage: function () {
                var message;
                if (!$scope.can_publish()) {
                    message = "You do not have permission to approve the report.";
                }
                else {
                    message = "To approve and publish the report change the status of the report from Draft to Publish in Subscription Packages and Approval section.";
                }
                $mdDialog.show(
                    $mdDialog.alert().clickOutsideToClose(true).textContent(message).ariaLabel('Alert').ok('Ok')
                );
            },

            parseArticleContent: function () {
                var wrapper = document.createElement('div');
                wrapper.innerHTML = $scope.article.content;
                $("#article-add-edit-content").html(wrapper.firstChild);
            }
        }
    });
