angular.module("SatBookApp")
    .factory("SatBookReportHelper",
        function SatBookReportHelper(GenericUtilsService) {

            var template = {
                templatePath: "/static/assets/khapps/sat_book/SatBookReport.html",
                title: "satBook.title",
                ariaLable: "satBook.title"
            };


            var CONSTANTS = {
                TEMPLATE: template,
            };

            function __createFieldDependentDict(field_data) {
                var fieldDependentData = {};
                for (var i = 0; i < field_data.length; i++) {
                    fieldDependentData[field_data[i].field_id] = {};
                }
                return fieldDependentData;
            }

            function __selectDefaultField(field_data) {
                var selectedField = {};
                if (!GenericUtilsService.isEmpty(field_data)) {
                    selectedField = field_data[0];
                }
                return selectedField;
            }

            return {
                CONSTANTS: CONSTANTS,
                createFieldDependentDict: __createFieldDependentDict,
                selectDefaultField: __selectDefaultField
            };
        });
