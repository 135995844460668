angular.module("TeamsApp").controller("EditTeamController",
    function ($scope, $rootScope, TeamsFactory, ListOfAllSubscriptionPackagesFactory, ListOfAllApplicationsFactory,
              $mdDialog, team, $khMessage, TeamsRegionsFactory, CachingUtilsService) {
        TeamsFactory.get({id: team}, function (result) {
            $scope.team = result;
        });
        function setUpForm() {
            $scope.edit_team_form = [
                {
                    "className": "flex-100",
                    "key": "team_name",
                    "type": "input",
                    "templateOptions": {
                        "label": "Team name",
                        required: true
                    }
                },
                {
                    "className": "flex-100",
                    type: "searchable_select",
                    key: "list_of_applications",
                    templateOptions: {
                        required: true,
                        label: "List of applications",
                        multiple: true,
                        labelProp: "app_name",
                        valueProp: "id",
                        options: $scope.all_applications
                    }
                },
                {
	                "className": "flex-100",
	                type: "searchable_select",
	                key: "regions",
	                templateOptions: {
		                required: true,
		                label: "Groups",
		                multiple: true,
		                labelProp: "name",
		                valueProp: "id",
		                options: $scope.regions
	                }
                },
	            {
                    "className": "flex-100",
                    type: "searchable_select",
                    key: "subscription_package",
                    templateOptions: {
                        required: true,
                        label: "Subscription packages",
                        multiple: true,
                        labelProp: "name",
                        valueProp: "id",
                        options: $scope.all_subscription_packages
                    }
                }
            ];
        }

        ListOfAllApplicationsFactory.query(function (result) {
            $scope.all_applications = result;
	        $scope.regions = CachingUtilsService.addFactoryToCache(TeamsRegionsFactory, "teams_regions");
            ListOfAllSubscriptionPackagesFactory.query(function (result) {
                $scope.all_subscription_packages = result;
                setUpForm();
            });
        });
        $scope.editTeam = function () {
	        delete $scope.team["region"];
            $scope.team.$update(function (result) {
                $khMessage.show("Updated successfully!");
                $mdDialog.hide();
	            $rootScope.$broadcast("team_updated");
            }, function (er) {
            });
        };
    });