angular.module("TrialApp").controller("ReportPreviewController", ["$location", "$scope", "$rootScope", "$routeParams", "$mdDialog",
    "preview_article","$sce",
    function ($location, $scope, $rootScope, $routeParams, $mdDialog, preview_article, $sce) {
        preview_article.content = $sce.trustAsHtml(preview_article.content);
        $scope.preview_article = preview_article;
        $scope.cancel = function () {
            $mdDialog.hide();
        };
    }
]);
