angular.module("TeamsApp").controller("TeamsListController", ["$scope", "$rootScope", "TeamUserStatisticsFactory", "$mdDialog", "UserSettingsFactory",
    function ($scope, $rootScope, TeamUserStatisticsFactory, $mdDialog, UserSettingsFactory) {
        $scope.permissions = permissions;
        $scope.currentPage = 1;
        $scope.currentStatsPage = 1;
        $scope.pageSize = 20;
        $scope.editTeamDialog = function (team) {
            $mdDialog.show({
                controller: "EditTeamController",
                templateUrl: '/static/assets/khapps/settings_v2/teams/partials/EditTeam.html',
                parent: angular.element(document.body),
                locals: {
                    team: team
                },
                clickOutsideToClose: false
            }).then(function () {
            })
        };

        function get_default_team() {
            UserSettingsFactory.get({}, function (result) {
                $scope.default_user_team = result.DEFAULT_USER_TEAM;
            })
        }

        get_default_team();

        function default_team_details(default_team_value) {
            TeamUserStatisticsFactory.get({team_slug: default_team_value}, function (result) {
                $scope.team = result;
            });
        }

        $scope.showTeamDetails = function (team) {
            $mdDialog.show({
                controller: "TeamDetailsController",
                skipHide: true,
                multiple: true,
                templateUrl: '/static/assets/khapps/settings_v2/teams/partials/ShowTeamDetails.html',
                parent: angular.element(document.body),
                locals: {
                    team: team
                },
                clickOutsideToClose: true
            })
        };

        function range(start, stop, step) {
            if (typeof stop == 'undefined') {
                stop = start;
                start = 1;
            }
            if (typeof step == 'undefined') {
                step = 1;
            }
            if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
                return [];
            }
            var result = [];
            for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
                result.push(i);
            }
            return result;
        }

        $scope.totalTeamPages = [];
        function getTeamStats() {
            TeamUserStatisticsFactory.query(function (result) {
                $scope.totalTeamPages = range(Math.ceil((result.count / $scope.pageSize)) + 1);
                $scope.teams = result.results;
                sessionStorage.setItem("team_statistics_current_page", 1);
            });
        }

        getTeamStats();
        $rootScope.$on("team_updated", function () {
            getTeamStats();
            $scope.team_search = {};
        });
        $scope.getMoreUserStatus = function (pageNum, query) {
            TeamUserStatisticsFactory.query({filter: query, page: pageNum}, function (result) {
                $scope.teams = result.results;
            });
        };

        $scope.nextPage = function(query) {
            var page = parseInt(sessionStorage.getItem("team_statistics_current_page")) + 1;
            if ($scope.totalTeamPages.indexOf(page) != -1) {
                $scope.getMoreUserStatus(page, query);
                sessionStorage.setItem("team_statistics_current_page", page);
            }
        };

        $scope.checkAbilityForNextPage = function () {
            var page = parseInt(sessionStorage.getItem("team_statistics_current_page")) + 1;
            return $scope.totalTeamPages.indexOf(page) != -1;
        };

        $scope.checkAbilityForPrevPage = function () {
            var page = parseInt(sessionStorage.getItem("team_statistics_current_page")) - 1;
            return $scope.totalTeamPages.indexOf(page) != -1;
        };

        $scope.prevPage = function(query) {
            var page = parseInt(sessionStorage.getItem("team_statistics_current_page")) - 1;
            if ($scope.totalTeamPages.indexOf(page) != -1) {
                $scope.getMoreUserStatus(page, query);
                sessionStorage.setItem("team_statistics_current_page", page);
            }
        };

        $scope.seachTeamStat = function (query) {
            TeamUserStatisticsFactory.query({filter: query}, function (result) {
                $scope.totalTeamPages = range(Math.ceil(result.count / $scope.pageSize) + 1);
                $scope.teams = result.results;
                sessionStorage.setItem("team_statistics_current_page", 1);
            });
            if (query["region"] == '' && query["team"] == '') {
                getTeamStats();
            }
        };

        $scope.teamSettingsDialog = function (ev) {
            $mdDialog.show({
                controller: "TeamSettingsController",
                templateUrl: '/static/assets/khapps/settings_v2/teams/partials/TeamsSettings.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false
            })
        };

        $scope.usersInTeamDialog = function (team_slug) {
            $mdDialog.show({
                controller: "TeamMembersController",
                skipHide: true,
                multiple: true,
                templateUrl: '/static/assets/khapps/settings_v2/teams/partials/TeamMembersDialog.html',
                parent: angular.element(document.body),
                locals: {
                    team_slug: team_slug,
                },
                clickOutsideToClose: true,
            });
        };
    }]);
