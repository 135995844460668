(function (angular) {
    "use strict";
    angular.module("TrialApp")
        .controller("TrialFiltersController",
            function TrialFiltersController($rootScope) {
                /**
                 * This Controller is used to control on which page which filters need to show
                 * for this purpose TrialFiltersResource is used to get dropdowns
                 */
                var vm = this;

                var date = new Date,
                    years = [{'name': 'All years', 'value': 'All'}],
                    year = date.getFullYear();

                for (var i = 2010; i < year + 20; i++) {
                    years.push({'name': i, 'value': i});
                }

                $rootScope.harvest_year_list = years;
                $rootScope.initial_harvest_year_value = year;

                $rootScope.getSort = function (sort_text) {
                    return sort_text;
                };

                vm.changeHarvestYear = function (selected_harvest_year) {
                    /**
                     * calls when harvest year value is changed from dropdown
                     */
                    if (selected_harvest_year &&
                        selected_harvest_year.value &&
                        selected_harvest_year.value !== $rootScope.selected_harvest_year) {
                        $rootScope.selected_harvest_year = selected_harvest_year.value;
                        $rootScope.$broadcast('updated_selected_harvest_year');
                    }
                };

                $rootScope.$on("team_changed", function (obj, slug) {
                    /**
                     * Listens team changes from team dropwdon which then triggers to change available dropdowns and page data
                     */
                    $rootScope.$broadcast('updated_selected_harvest_year');
                });
            });
})(angular);
