angular.module("TeamsApp").controller('PrimaryTeamDetailsController',
    function ($scope, $rootScope, TeamUserStatisticsFactory, PrimaryTeamOfUserFactory, PlatformSettingsService) {

        PrimaryTeamOfUserFactory.get(function (result) {
            $scope.primary_team = result;
            TeamUserStatisticsFactory.get({team_slug: $scope.primary_team.team_slug}, function (result) {
                $scope.team = result;
            });
        });

        PlatformSettingsService.promise.then(function(settings) {
            $scope.build_tag = settings.BUILD_TAG;
        });

    });