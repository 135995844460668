import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
HC_more(Highcharts);

angular.module('SatBookApp')
    .controller('SatBookReportController',
        function($rootScope, $scope, $mdDialog, $filter, $khMessage, DateTimeHelperFactory,
                 SatBookReportHelper, GenericMapHelper, SatelliteServiceTimeSeriesResource,
                 SatellitePublishedDatasetResource, SatelliteServiceDeleteReasonResource, FarmFieldResource,
                 FieldPlotCropFactory, KisanHubIconsResource, selected_object_type,
                 ErrorLoggingService,
                 selected_object_slug, growing_season) {
            var vm = this;
            vm.khIcon = KisanHubIconsResource.khIcons;
            var MapHelper = __mapHelpers();
            var AppHelpers = appHelpers();
            var mapid = 'detailMap';
            var fieldLayer;
            var plotLayer;
            var process_name;
            var object_slug;
            var visible_layer_name;

            vm.ksatgeoserver = $rootScope.ksatgeoserver;
            vm.page = 1;
            vm.total_pages = 0;
            GenericMapHelper.createMap(vm);
            vm.dialogDict = SatBookReportHelper.CONSTANTS.TEMPLATE;
            vm.userSettingDateFormat = $rootScope.userSettingDateFormat;
            vm.pieChartConfig = {
                options: {},
                exporting: {
                    enabled: false
                },
                lang: {
                    noData: 'Loading Data...'
                },
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                tooltip: {
                    crosshairs: true,
                    shared: true,
                    valueSuffix: '',
                },
                legend: {
                    align: 'center',
                    verticalAlign: 'top',
                    floating: true,
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: false
                },
                title: {
                    text: '',
                },
                subtitle: {
                    text: vm.selected_date,
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}<br> {point.y:.1f}ha ({point.percentage:.1f}%)',
                            style: {
                                color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                            },
                            // distance: -50,
                            filter: {
                                property: 'percentage',
                                operator: '>',
                                value: 0
                            }
                        }
                    }
                },
                series: vm.pie_series_data
            };

            vm.lineChartConfig = {
                options: {},
                exporting: {
                    enabled: false
                },
                lang: {
                    noData: 'Loading Data...'
                },
                title: {
                    text: vm.field_progress_chart_title,
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    type: 'datetime',
                    title: {
                        text: vm.field_progress_chart_x_axis_title
                    },
                },
                yAxis: {
                    title: {
                        text: vm.field_progress_chart_y_axis_title
                    },
                    min: 0
                },
                tooltip: {
                    crosshairs: true,
                    shared: true,
                    valueSuffix: '',
                },
                plotOptions: {
                    spline: {
                        marker: {
                            radius: 4,
                            lineColor: '#666666',
                            lineWidth: 1
                        }
                    }
                },
                legend: {
                    style: {
                        display: 'none'
                    }
                },
                series: []
            };

            function get_field_data(selectedField) {
                FarmFieldResource.get({
                    'field_slug': selectedField,
                    'content_type': 'geojson'
                }, function (data) {
                    vm.field_geojson = data;
                    fieldLayer = MapHelper.createFieldLayer();
                    if (selected_object_type === 'FarmField') {
                        vm.selectionBounds = fieldLayer.getBounds();
                        vm.dialogDict.title = 'Satellite imagery for ' + vm.field_geojson.properties.field_name + ' (' + round_to_two_decimals(vm.field_geojson.properties.workable_area) + ' ha)';
                    }
                    init(growing_season);
                });
            }

            function addpage() {
                vm.page += 5;
            }

            function get_field_progress_data(object_slug) {
                return SatelliteServiceTimeSeriesResource.get({field_slug: object_slug}, function (result_data) {
                    return result_data;
                });
            }

            function round_to_two_decimals(number) {
                return Math.round(number * 100) / 100;
            }

            function get_fieldplotcrop_data(selectedFieldPlotCrop) {
                FieldPlotCropFactory.get({
                    'field_plot_crop_slug': selectedFieldPlotCrop,
                    'data_format': 'geojson'
                }, function (data) {
                    vm.plot_geojson = data;
                    plotLayer = MapHelper.createPlotLayer();
                    vm.selectionBounds = plotLayer.getBounds();
                    vm.dialogDict.title = 'Satellite imagery for: ' + vm.plot_geojson.properties.plot_name + ' (' + round_to_two_decimals(vm.plot_geojson.properties.plot_area) + ' ha)';
                    selectedField = vm.plot_geojson.properties.field_slug;
                    get_field_data(selectedField);
                });
            }

            if (selected_object_type === 'FarmField') {
                var selectedField = selected_object_slug;
                vm.selectedSatelliteField = selectedField;
                get_field_data(selectedField);
            }

            if (selected_object_type === 'FieldPlotCrop') {
                var selectedFieldPlotCrop = selected_object_slug;
                vm.selectedSatelliteField = selectedFieldPlotCrop;
                get_fieldplotcrop_data(selectedFieldPlotCrop);
            }

            vm.cancel_sat_book = function () {
                $mdDialog.hide();
            };

            function create_field_progress_chart(process_name) {
                var process_unit;
                var latest_date = 1;
                var latest_overlay_slug;
                var overlayLayers = vm.layers['overlays'];
                for (var prop in overlayLayers) {
                    var overlay_layer = overlayLayers[prop];
                    if (angular.isDefined(overlay_layer.metadata)) {
                        if (overlay_layer.metadata.statistics !== null && overlay_layer.metadata.service.processing_type.process_name === process_name) {
                            if (latest_date <= overlay_layer.metadata.acquisition_timestamp) {
                                latest_date = overlay_layer.metadata.acquisition_timestamp;
                                latest_overlay_slug = overlay_layer.slug;
                                visible_layer_name = prop;
                            }
                            process_unit = overlay_layer.metadata.service.processing_type.unit;
                        }
                    }
                }

                var averages = _.map(vm.field_progress[process_name.replace(' ', '')], function (item) {
                    return [item.date, item.smooth];
                });

                if (visible_layer_name) {
                    vm.layers['overlays'][visible_layer_name].visible = true;
                    var field_progress_line_chart_data = [
                        {
                            showInLegend: false,
                            name: 'Estimated ' + process_name,
                            data: averages,
                            zIndex: 1,
                            type: 'spline',
                            tooltip: {
                                valueDecimals: 2
                            },
                            marker: {
                                fillColor: 'white',
                                lineWidth: 2,
                                lineColor: Highcharts.getOptions().colors[0]
                            }
                        },
                    ];

                    vm.field_progress_chart_title = 'Field Progress Over Time';
                    vm.field_progress_chart_x_axis_title = 'Date';
                    vm.lineChartConfig.title.text = vm.field_progress_chart_title;
                    vm.lineChartConfig.xAxis.title.text = vm.field_progress_chart_x_axis_title;
                    vm.field_progress_chart_y_axis_title = process_name;
                    if (process_unit)
                        vm.field_progress_chart_y_axis_title = vm.field_progress_chart_y_axis_title + ' (' + process_unit + ')';
                    vm.lineChartConfig.yAxis.title.text = vm.field_progress_chart_y_axis_title;
                    vm.lineChartConfig.series = field_progress_line_chart_data;
                    vm.lineChartConfig.yAxis.max = get_max_value_for_process(process_name);
                    vm.latest_overlay_slug = latest_overlay_slug;
                    object_slug = vm.latest_overlay_slug;
                    load_layer_information(object_slug);
                }
            }

            function get_max_value_for_process(process_name) {
                if (process_name === 'CropCover') {
                    return 100;
                }
                if (process_name === 'GROUNDCOVER') {
                    return 100;
                }
                if (process_name === 'LAI') {
                    return 5;
                }
                if (process_name === 'IRECI') {
                    return 2;
                }
                else {
                    return 1;
                }
            }

            function load_layer_information(object_slug) {
                SatellitePublishedDatasetResource.get({publish_slug: object_slug}, function (result_data) {
                    vm.satelliteLayerInformation = result_data.data;
                    var area_stats = vm.satelliteLayerInformation.areastatistics_set;
                    if (angular.isDefined(area_stats)) {
                        var area_stats_pie_chart_data = [];
                        area_stats.forEach(function (d) {
                            area_stats_pie_chart_data.push({
                                name: vm.satelliteLayerInformation.service.processing_type.process_name + ': ' + d.lower_band + '-' + d.upper_band + ' ' + vm.satelliteLayerInformation.service.processing_type.unit,
                                y: Math.round(d.value * 100) / 100,
                                color: d.color_value
                            });
                        });

                        var pie_series_data = [{
                            name: 'Hectarage',
                            data: area_stats_pie_chart_data
                        }];
                        vm.selected_date = '';
                        vm.pieChartConfig.subtitle.text = vm.selected_date;
                        vm.pieChartConfig.series = pie_series_data;
                        vm.show_field_overlay_layer_list_EmptyState = false;
                    }

                }, function (error) {
                    ErrorLoggingService.genericAPIError(error);
                });
            }

            function init(growing_season) {
                getSatelliteLayers(growing_season);
                vm.field_progress = get_field_progress_data(selectedFieldPlotCrop);
            }

            function create_overlay_layer_dict(result_data) {
                /**
                 * Process the satellite layer stats and images from the kisansat API into a convenient dictionary.
                 * Image Overlay svg layers are read from S3, located in the same bucket as the thumbnails but with a
                 * different suffix. We require field bounds in order to constrain where these images are drawn.
                 */
                vm.kisansat_data_list = result_data.data;
                for (var i = 0; i < vm.kisansat_data_list.length; i++) {
                    var layername = vm.kisansat_data_list[i].workspace + ':' + vm.kisansat_data_list[i].layer;
                    var legendname = layername;
                    var raster_legend_options = '&LEGEND_OPTIONS=fontSize:10;dx:2;layout:vertical';
                    var vector_legend_options = '&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:5;bgColor:0xFFFFFF;dpi:180';
                    var process_description = vm.kisansat_data_list[i].service.processing_type.description;
                    var process_name = vm.kisansat_data_list[i].service.processing_type.process_name;
                    var mode_upper_band = '';
                    var total_area = '';
                    if (vm.kisansat_data_list[i].statistics) {
                        var mode_upper_band = vm.kisansat_data_list[i].statistics.mode_upper_band;
                        var total_area = vm.kisansat_data_list[i].statistics.total_area;
                    }
                    vm.show_overlay_layer_list_EmptyState = false;
                    vm.showSatelliteImages = true;
                    var imageOverlayLayer = {
                        slug: vm.kisansat_data_list[i].slug,
                        field_slug: vm.kisansat_data_list[i].field_slug,
                        is_uav: false,
                        metadata: vm.kisansat_data_list[i],
                        acquisition_date: vm.kisansat_data_list[i].acquisition_timestamp,
                        satellite_name: vm.kisansat_data_list[i].service.satellite_data_provider.satellite_name,
                        process_name: process_name,
                        mode_upper_band: mode_upper_band,
                        total_area: total_area,
                        thumbnail_url: vm.kisansat_data_list[i].thumbnail_url,
                        rgb_thumbnail_url: vm.kisansat_data_list[i].thumbnail_url.replace(process_name, 'RGB'),
                        large_thumbnail_url: vm.kisansat_data_list[i].large_image_url,
                        rgb_large_thumbnail_url: vm.kisansat_data_list[i].large_image_url.replace(process_name, 'RGB'),
                        visible: false,
                        name: vm.kisansat_data_list[i].layer,
                        type: 'imageOverlay',
                        url: vm.kisansat_data_list[i].thumbnail_url.replace('_colored_thumbnail.png', '.svg'),
                        bounds: vm.selectionBounds,
                        layerParams: {
                            showOnSelector: false,
                            attribution: 'KisanSat'
                        },
                        group: process_name,
                        page: Math.floor(i / 10) + vm.total_pages
                    };
                    vm.layers['overlays'][vm.kisansat_data_list[i].layer] = imageOverlayLayer;
                }
            }

            function getSatelliteLayers(growing_season, pageNum) {
                var format_json = 'json';
                if (pageNum) {
                    vm.queryParams = {
                        format: format_json,
                        field_slug: vm.selectedSatelliteField,
                        page: pageNum,
                        growing_season: growing_season
                    };
                } else {
                    vm.queryParams = {
                        format: format_json,
                        field_slug: vm.selectedSatelliteField,
                        growing_season: growing_season
                    };
                }
                SatellitePublishedDatasetResource.query(vm.queryParams, function (result_data) {
                    if (result_data.count > 0) {
                        create_overlay_layer_dict(result_data);
                    }
                    if (result_data.next) {
                        var newPage = result_data.current_page + 1;
                        getSatelliteLayers(growing_season, newPage);
                    }
                    else {
                        build_ksat_overlay_layers_field_process_unique_list();
                    }
                    vm.total_pages += Math.floor(result_data.count / 10);
                });
            }

            function build_ksat_overlay_layers_field_process_unique_list() {
                vm.show_field_overlay_layer_list_EmptyState = {};
                var overlay_layer_list = [];
                var unique = {};
                var distinct = [];
                var group_overlay_data = [];
                var count_layers = 0;
                for (var prop in vm.layers['overlays']) {
                    group_overlay_data.push({
                        group: vm.layers['overlays'][prop].field_slug + '|' + vm.layers['overlays'][prop].group,
                        overlay_layer_slug: prop
                    });
                    count_layers += 1;
                }
                vm.overlaid_layers_count = count_layers;
                group_overlay_data.forEach(function (d) {
                    if (!unique[d.group]) {
                        distinct.push(d.group);
                        unique[d.group] = true;
                    }
                });
                distinct.forEach(function (x) {
                    var res = x.split('|');
                    if (res[0] !== 'undefined') {
                        var fieldvalue = vm.field_geojson; //get_field_Feature($rootScope, res[0], 'field_slug');
                        if (angular.isDefined(fieldvalue)) {
                            overlay_layer_list.push(create_overlay_layer_append_dict(fieldvalue, res, x));
                            vm.show_field_overlay_layer_list_EmptyState[res[0]] = true;
                        }
                        else {
                            remove_overlay_layer_for_unmapped_field(res);
                        }
                    }
                });
                vm.overlay_layer_list = overlay_layer_list.filter(function (e) {
                    return e.value;
                });
                if (vm.overlay_layer_list.length) {
                    vm.overlay_layer_list = _.sortBy(vm.overlay_layer_list, 'process_name').reverse();
                    vm.selected_process = vm.overlay_layer_list[0].process_name;
                }
                process_name = vm.selected_process;
                create_field_progress_chart(vm.selected_process);

            }

            function create_overlay_layer_append_dict(fieldvalue, res, x) {
                return {
                    field_slug: res[0],
                    field_name: fieldvalue.properties.farm_name + '-' + fieldvalue.properties.field_name,
                    process_name: res[1],
                    name: x,
                    value: x
                };
            }

            function remove_overlay_layer_for_unmapped_field(res) {
                for (var prop in vm.layers['overlays']) {
                    if (vm.layers['overlays'][prop].field_slug === res[0]) {
                        delete vm.layers['overlays'][prop];
                    }
                }
            }

            function changeSelectedLayer(new_object_slug, new_process_name, new_object_date, visibility, visible_layer_key) {
                if (process_name !== new_process_name) {
                    process_name = new_process_name;
                    create_field_progress_chart(process_name);
                }
                if (new_object_slug !== object_slug) {
                    object_slug = new_object_slug;
                    load_layer_information(object_slug, process_name);
                    vm.selected_overlay_item = object_slug;
                    vm.height = 12;
                }
                vm.layers['overlays'][visible_layer_name].visible = false;
                visible_layer_name = visible_layer_key;
                vm.layers['overlays'][visible_layer_name].visible = true;
            }

            vm.changeSelectedLayer = changeSelectedLayer;
            vm.addpage = addpage;

            vm.onTabChanges = function (new_process_name) {
                process_name = new_process_name;
                vm.layers['overlays'][visible_layer_name].visible = false;
                create_field_progress_chart(process_name);
            };

            vm.deleteSatelliteLayer = function (key, object_slug) {
                var subscriptionDict = {
                    'published_dataset_slug': object_slug,
                    'published_dataset_key': key
                };
                SatelliteServiceDeleteReasonResource.get(function (result_data) {
                    vm.delete_options = result_data.data;
                    vm.satellite_published_data_deletion = subscriptionDict;
                    vm.satellite_published_data_deletion_form = AppHelpers.load_satellite_delete_service(vm.delete_options);
                });

                $mdDialog.show({
                    controller: function () {
                        return self;
                    },
                    controllerAs: 'vm',
                    templateUrl: '/static/assets/khapps/sat_book/includes/SatBookReportDatasetDeleteReasonsForm.html',
                    parent: angular.element(document.body),
                    scope: $scope,
                    skipHide: true,
                    multiple: true,
                    preserveScope: true,
                    clickOutsideToClose: false
                });
            };

            vm.delete_satellite_published_data_delete = function () {
                var delete_reason_slug = vm.satellite_published_data_deletion.delete_reason_slug;
                var object_slug = vm.satellite_published_data_deletion.published_dataset_slug;

                SatellitePublishedDatasetResource.delete({
                    publish_slug: object_slug,
                    delete_reason_slug: delete_reason_slug
                }, function (result_data) {
                    $khMessage.show($filter('translate')('deleted_successfully'), 5000);
                    delete vm.layers['overlays'][vm.satellite_published_data_deletion.published_dataset_key];
                }, function (error) {
                    ErrorLoggingService.genericAPIError(error);
                });
                $mdDialog.hide();
            };

            vm.hide = function () {
                $mdDialog.hide();
            };

            vm.cancel = function () {
                $mdDialog.cancel();
            };

            function appHelpers() {
                return {
                    load_satellite_delete_service: function (delete_options) {
                        return [
                            {
                                className: 'flex-100',
                                type: 'radio',
                                key: 'delete_reason_slug',
                                id: 'service_id',
                                templateOptions: {
                                    required: true,
                                    label: '',
                                    valueProp: 'slug',
                                    labelProp: 'dl.delete_resason',
                                    options: delete_options
                                }
                            }
                        ];
                    },
                };
            }

            function __mapHelpers() {
                return {
                    createFieldLayer: function () {
                        if (vm.field_geojson.type === 'FeatureCollection') {
                            for (var i = 0; i < vm.field_geojson.features.length; i++) {
                                if (vm.field_geojson.features[i].properties.field_slug == vm.selectedField) {
                                    vm.selected_field_geojson = {
                                        'type': 'FeatureCollection',
                                        'features': [vm.field_geojson.features[i]]
                                    };
                                    return GenericMapHelper.createFieldLayer(vm.selected_field_geojson, mapid);
                                }
                            }
                        }
                        else if (vm.field_geojson.type === 'Feature') {
                            return GenericMapHelper.createFieldLayer(vm.field_geojson, mapid);
                        }
                    },

                    createPlotLayer: function () {
                        if (vm.plot_geojson.type === 'FeatureCollection') {
                            for (var i = 0; i < vm.plot_geojson.features.length; i++) {
                                if (vm.plot_geojson.features[i].properties.plot_slug == vm.selectedFieldPlotCrop) {
                                    vm.selected_plot_geojson = {
                                        'type': 'FeatureCollection',
                                        'features': [vm.plot_geojson.features[i]]
                                    };
                                    return GenericMapHelper.createPlotLayer(vm.selected_plot_geojson, mapid);
                                }
                            }
                        }
                        else if (vm.plot_geojson.type === 'Feature') {
                            return GenericMapHelper.createPlotLayer(vm.plot_geojson, mapid);
                        }
                    }

                };
            }
        });


