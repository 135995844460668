import { ProfileComponent } from '../../../angular/src/app/settings/profile/profile.component';
import {downgradeComponent} from '@angular/upgrade/static';   

export default angular.module("TeamsApp", ["PlatformStatisticsApp", "UserSettingsApp"]);


angular.module('TeamsApp')
.directive(
    'editProfile',
    downgradeComponent({component: ProfileComponent})
);