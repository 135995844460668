angular.module("SettingsApp").config(["$routeProvider", "$resourceProvider", "$locationProvider",
    function ($routeProvider, $resourceProvider) {
        $resourceProvider.defaults.stripTrailingSlashes = false;
        $routeProvider
            .when("/", {
                name: '',
                controller: "SettingsLandingPageController",
                templateUrl: "/static/assets/khapps/settings_v2/general/partials/SettingsLandingPage.html"
            })
            .when("/teams/", {
                name: '',
                controller: "TeamsSettingsLandingPageController",
                templateUrl: "/static/assets/khapps/settings_v2/teams/partials/TeamsSettingsLandingPage.html"
            })
            .otherwise({
                redirectTo: '/'
            });
    }
]);
