(function (angular) {
    "use strict";
    angular.module("TrialApp")
        .controller("TrialNavbarAddButtonController", TrialNavbarAddButtonController);

    TrialNavbarAddButtonController.$inject = ["$rootScope", "$location", "$mdDialog"];

    function TrialNavbarAddButtonController($rootScope, $location, $mdDialog) {

        /**
         * This controller is used for to show add dropdown options on page.
         * partial file: /static/assets/khapps/trial/apps/page_setup/add_dropdown/TrialNavbarAddButton.html
         */
        var vm = this;
        var locationPath = "";
        _init();

        $rootScope.$on("$locationChangeStart", function (event) {
                /**
                 *This listener used to to listen route change/ page change  as
                 * Add dropdown varies per page but as this is sibling controller it
                 * doesnt refreshes. But to change vm.navbarLinks we have put listener on route change
                 */
                _init();
            }
        );

        function _init() {
            locationPath = $location.path();
            vm.navbarLinks = [
                {name: "Report", action: "addReport"}
            ];
        }

        vm.callAddForm = function (actionName) {
            $mdDialog.show({
                controller: 'ReportLandingAddController',
                templateUrl: "/static/assets/khapps/trial/apps/report_dashboard/member_rapid/form/partials/trial_report_form.html",
                controllerAs: "dl",
                parent: angular.element(document.body),
                skipHide: true,
                multiple: true,
                escapeToClose: false,
                animation: true
            }).then(function (response_data) {
                $rootScope.$broadcast('addReport', response_data);
            });
        };
    }
})
(angular);
