(function (angular) {
    "use strict";
    angular.module('TrialApp')
        .controller('NewSectionAddController', NewSectionAddController);

    NewSectionAddController.$inject = ['$mdDialog', '$scope'];

    function NewSectionAddController($mdDialog, $scope) {
        var AppHelpers = appHelpers();

        AppHelpers.initObject();
        AppHelpers.buildForm();

        $scope.saveNewSection = function () {
            AppHelpers.saveNewSection();
        };

        function appHelpers() {
            return {
                initObject: function () {
                    $scope.newSection = {};
                    $scope.dialogDict = {
                        templatePath: "/static/assets/khapps/trial/apps/report_dashboard/member_rapid/supporting_apps/newsection/NewSectionForm.html",
                        title: "New Section",
                        ariaLable: "Add Section View",
                        disableFullscreen: "true"
                    };
                },

                buildForm: function () {
                    $scope.NewSectionFields = [
                        {
                            className: "flex-100",
                            type: "input",
                            key: "title",
                            templateOptions: {
                                required: true,
                                label: "Section Title",
                                placeholder: "Enter section title",
                                maxlength: 100
                            }
                        }]
                },

                saveNewSection: function () {
                    $mdDialog.hide($scope.newSection);
                }
            }
        }
    }

})(angular);