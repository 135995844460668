(function (angular) {
    "use strict";
    angular.module("TrialApp")
        .factory("GenericReportHelper", GenericReportHelper);

    GenericReportHelper.$inject = ["TrialAppHelper"];

    function GenericReportHelper(TrialAppHelper) {
        var CONSTANTS = {
                TEMPLATES: {
                    form: {
                        title: "report.card.title",
                        ariaLable: "Report Add/Edit View",
                        disableFullscreen: "true"
                    },
                    cards: [
                        {
                            card_title: "Reports",
                            kh_card_content_template: "/static/assets/khapps/trial/apps/report_dashboard/member_rapid/table/report_table_data.html",
                            card_flex: 100,
                            show_progress_bar: true
                        }
                    ],
                    memberReport: {
                        listView: {
                            templatePath: "/static/assets/khapps/trial/apps/report_dashboard/member_rapid/form/partials/member_report/member_reports_table_data.html",
                            title: "Agronomy Reports",
                            ariaLable: "Agronomy Reports List"
                        },
                        addView: {
                            templatePath: "/static/assets/khapps/trial/apps/report_dashboard/member_rapid/form/partials/common_includes/new_report_form.html",
                            title: "New Agronomy Report",
                            ariaLable: "New Agronomy Report",
                            disableFullscreen: "true"
                        }
                    },
                    rapidReport: {
                        listView: {
                            templatePath: "/static/assets/khapps/trial/apps/report_dashboard/member_rapid/form/partials/rapid_report/rapid_reports_table_data.html",
                            title: "Variety Reports",
                            ariaLable: "Variety Reports"
                        },
                        addView: {
                            templatePath: "/static/assets/khapps/trial/apps/report_dashboard/member_rapid/form/partials/common_includes/new_report_form.html",
                            title: "New Variety Report",
                            ariaLable: "New Variety Report",
                            disableFullscreen: "true"
                        }
                    }
                },
                EDIT_TEMPLATES: {
                    pageTitle: "Trial Reports",
                    cards: [
                        {
                            kh_card_content_template: "/static/assets/khapps/trial/apps/report_dashboard/member_rapid/form/report_add_edit.html",
                            card_flex: 100,
                            show_progress_bar: true
                        }
                    ],
                    form: {
                        templatePath: "/static/assets/khapps/trial/apps/report_dashboard/member_rapid/form/report_add_edit.html",
                        title: "report.edit.title",
                        ariaLable: "Add/Edit report form"
                    },
                    statusForm: {
                        templatePath: "/static/assets/khapps/trial/apps/report_dashboard/member_rapid/form/partials/report_status_change.html",
                        title: "report.form.title",
                        ariaLable: "Add/Edit report form"
                    },
                    previewReport: {
                        templatePath: "/static/assets/khapps/trial/partials/report/report_preview.html",
                        title: "report.card.title",
                        ariaLable: "Preview Report"
                    }
                }
            }
        ;
        var TABLE_FILTERS = {
            application_code: TrialAppHelper.getFilterDict("treatmentApplication", "application_code"),
            start_time: TrialAppHelper.getFilterDict("treatmentApplication", "readable_start_time", "start_time"),
            end_time: TrialAppHelper.getFilterDict("treatmentApplication", "readable_end_time", "end_time"),
            crop_stage: TrialAppHelper.getFilterDict("treatmentApplication", "crop_stage"),
            location: TrialAppHelper.getFilterDict('common', 'location'),
            farm: TrialAppHelper.getFilterDict('common', 'farm'),
            field: TrialAppHelper.getFilterDict('common', 'farm_field', 'field'),
            field_plot_crop: TrialAppHelper.getFilterDict('common', 'field_plot_crop', 'plot')
        };

        return {
            CONSTANTS: CONSTANTS,
            TABLE_FILTERS: TABLE_FILTERS
        };

    }

})(angular);