angular.module("TeamsApp").controller('TeamDetailsController', ['$scope', '$rootScope', 'team', '$mdDialog', '$khMessage', 'TeamMembersFactory', 'ProfileFactory',
    function ($scope, $rootScope, team, $mdDialog, $khMessage, TeamMembersFactory, ProfileFactory) {
        $scope.team = team;
        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        TeamMembersFactory.get({team_slug: $scope.team.team_slug}, function (result) {
            $scope.team_members = result;
        });

        ProfileFactory.get(function (result) {
            $scope.user_profile = result;
        });

        $scope.activateDeactivateUser = function (index) {
            var username = $scope.team_members.list_of_users_in_team[index].username;
            var status = !$scope.team_members.list_of_users_in_team[index].is_active;
            if (status) {
                var deactivateConfirm = $mdDialog.confirm()
                    .title('Deactivate user')
                    .textContent('Are you sure you want to deactivate the user?')
                    .ariaLabel('Deactivate user')
                    .ok('Deactivate')
                    .cancel('Cancel');
                $mdDialog.show(deactivateConfirm).then(function () {
                    window.location = '/profiles/viewteam/' + username + '/deactivate_user/';
                }, function () {
                    $scope.team_members.list_of_users_in_team[index].is_active = !$scope.team_members.list_of_users_in_team[index].is_active;
                });
            }
            else {
                var activateConfirm = $mdDialog.confirm()
                    .title('Activate user')
                    .textContent('Are you sure you want to activate the user?')
                    .ariaLabel('Activate user')
                    .ok('Activate')
                    .cancel('Cancel');
                $mdDialog.show(activateConfirm).then(function () {
                    window.location = '/profiles/viewteam/' + username + '/activate_user/';
                }, function () {
                    $scope.team_members.list_of_users_in_team[index].is_active = !$scope.team_members.list_of_users_in_team[index].is_active;
                });
            }
        };
    }]);