angular
    .module('SettingsApp')
    .controller('TeamStatisticsSecondaryNavbarController', [
        '$scope',
        '$mdDialog',
        function($scope, $mdDialog) {
            $scope.createTeamDialog = function(ev) {
                $mdDialog.show({
                    controller: 'CreateTeamController',
                    templateUrl:
                        '/static/assets/khapps/settings_v2/general/partials/CreateTeam.html',
                    parent: angular.element(document.body),
                    targetEvent: ev,
                    clickOutsideToClose: false
                });
            };
        }
    ]);
