angular.module("SettingsApp").factory("DjangoSitesFactory", ["$resource", function ($resource) {
    return $resource("/api/v1.0/khcore/sites/:id/", {id: '@id'}, {
        create: {
            method: 'POST'
        },
        get: {
            method: 'GET'
        },
        update: {
            method: 'PUT'
        }
    });
}]);