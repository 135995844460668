angular.module("AccountSettingsApp").controller('AccountSettingsController',
    ['$scope', '$rootScope', 'UserAccountSettingFactory', '$khMessage', '$khDialogClose',
        function ($scope, $rootScope, UserAccountSettingFactory, $khMessage, $khDialogClose) {

            UserAccountSettingFactory.get({id: settings_id}, function (result) {
                $scope.user = result;
            });

            $scope.updateAccount = function (data) {
                if (data['password']) {
                    var redirect = true;
                }
                delete data['password2'];
                $scope.user.$update(function (result) {
                    if (redirect) {
                        $khMessage.show('Updated successfully');
                        location.reload();
                    } else {
                        $khDialogClose();
                        $khMessage.show('Updated successfully');
                    }
                }, function (error) {
                    $khMessage.show(error.data.message);
                });
            };

            $scope.account_update_form = [
                {
                    'className': 'flex-100',
                    'key': 'first_name',
                    'type': 'input',
                    'templateOptions': {
                        'label': 'First name',
                        required: true
                    }
                },
                {
                    'className': 'flex-100',
                    'key': 'last_name',
                    'type': 'input',
                    'templateOptions': {
                        label: 'Last name',
                        required: true
                    }
                },
                {
                    'className': 'flex-100',
                    'key': 'email',
                    'type': 'input',
                    'templateOptions': {
                        type: 'email',
                        'label': 'Email',
                        required: true
                    }
                },
                {
                    'className': 'flex-100',
                    'key': 'password',
                    'type': 'password',
                    'templateOptions': {
                        'label': 'Password'
                    }
                },
                {
                    'className': 'flex-100',
                    'key': 'password2',
                    'type': 'password',
                    'templateOptions': {
                        'label': 'Confirm password'
                    }
                }
            ];
        }
    ]
);