angular.module("TrialApp").run(function ($http, $rootScope, $routeParams, $location, $timeout, $window, $mdDialog) {
    $http.defaults.xsrfCookieName = 'csrftoken';
    $http.defaults.xsrfHeaderName = 'X-CSRFToken';

    $rootScope.in_app_add_button = '/static/assets/khapps/trial/apps/page_setup/add_dropdown/TrialNavbarAddButton.html';
    $rootScope.select_filters_template = '/static/assets/khapps/trial/apps/page_setup/filters/TrialFilters.html';

    $rootScope.cancel = function () {
        $mdDialog.cancel();
    };
    $rootScope.hide = function () {
        $mdDialog.hide();
    };

});
