angular.module("TrialApp").factory("ArticlesReportFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/articles/articlesreport/', {}, {
        get: {
            params: {report_slug: '@report_slug'},
            url: '/api/v1.0/articles/articlesreport/:report_slug/',
            method: 'GET',
            isArray: true
        },
        query: {
            params: {article_type: '@article_type', trials: '@trials', publish_year: '@publish_year'},
            url: '/api/v1.0/articles/articlesreport/',
            isArray: true
        }
    });
}]);