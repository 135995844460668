(function (angular) {
    "use strict";
    angular.module('TrialApp')
        .factory('ReportEditHelper', ReportEditHelper);

    ReportEditHelper.$inject = ['$compile'];

    function ReportEditHelper($compile) {
        var CONSTANTS = {
            imageuploadsconfig: {
                show_images: true,
                app_tag: 'Articles',
                max_files: 1,
                db_type: 'mysql',
                display_type: 'detailed',
                allow_duplicates: true
            }
        };

        function makeCellsResizable() {
            var tdElm;
            var startOffset;

            Array.prototype.forEach.call(
                document.querySelectorAll("#trial-report tr:first-child td"),
                function (td) {
                    td.style.position = 'relative';
                    if ($(td).find("div.td-grip").length == 0) {
                        var grip = document.createElement('div');
                        grip.innerHTML = "&nbsp;";
                        grip.className = 'td-grip';
                        grip.contentEditable = false;
                        grip.addEventListener('mousedown', function (e) {
                            tdElm = td;
                            startOffset = td.offsetWidth - e.pageX;
                        });
                        td.appendChild(grip);
                    }
                });

            $("#trial-report tr:first-child").on('mousemove', function (e) {
                var trWidth = $(this).width();
                var tdWidth = startOffset + e.pageX;

                if (tdElm) {
                    tdElm.width = (100 * tdWidth) / trWidth + "%";
                }
            });

            document.addEventListener('mouseup', function () {
                tdElm = undefined;
            });

        }

        function setWidthInPercentage() {
            $("#trial-report tr:first-child").each(function () {
                var trWidth = $(this).width();
                $(this).find('td').each(function () {
                    var tdWidth = $(this).outerWidth();
                    $(this).attr("width", (100 * tdWidth) / trWidth + "%");
                    $(this).attr("class", "kh-td-width");
                });
            });
        }

        function centerExistingImg() {
            $("#trial-report img").each(function () {
                if (!this.parentElement.getAttribute("align")) {
                    $(this).replaceWith("<p align='center'>" + this.outerHTML + "</p>");
                }
            });
            setHeightWidthForImg();
        }

        function setHeightWidthForImg() {
            $("#trial-report img").each(function () {
                var width, height;
                if (this.style.width) {
                    width = parseInt(this.style.width.replace("px", ""));
                    height = parseInt(this.style.height.replace("px", ""));
                } else {
                    width = this.width;
                    height = this.height;
                }
                if (width > 800) {
                    height = ((800 / width) * height);
                    width = 800;
                    this.height = height.toString();
                    this.width = width.toString();
                    this.style.height = height + "px";
                    this.style.width = width + "px";
                }
            });
        }

        function findOkBtn() {
            $(".cke_browser_webkit .cke_dialog_ui_button_ok").on('click', function () {
                centerExistingImg();
            });
        }

        function addDeleteContentBtn($scope) {
            var exclude_content_id_list = ["header-content", "objective-content", "summary-content", "treatment-content", "results-content"];
            var content_id_list = ["footer-content"];
            $("#trial-report .editor-wrapper").each(function () {
                if (this.id.indexOf("content") != -1) {
                    content_id_list.push(this.id)
                }
            });

            var content_list = content_id_list.difference(exclude_content_id_list);
            removeReportDeleteBtn();
            for (var i = 0; i < content_list.length; i++) {
                var mdButton = "<md-button class='report-delete-btn' ng-click=removeDiv('" + content_list[i] + "') aria-label='Close dialog'> Remove </md-button>";
                var content = $compile(mdButton);
                $("#" + content_list[i]).prepend(content($scope));
            }
        }

        function removeReportDeleteBtn() {
            $(".report-delete-btn").remove();
        }

        function addNewSectionBtn($scope) {
            removeNewSectionBtn();
            var content_to_ignore = ["header-content", "objective-content", "summary-content", "commentary-content"];
            $("#trial-report .editor-wrapper").each(function () {
                var mdButton = "<div class='report-new-section' align='center'><md-button class='report-new-section-btn' ng-click=addSection('" + this.id + "') aria-label='Close dialog'> <md-icon md-svg-icon='plus'></md-icon> Add New Section </md-button></div>";
                var content = $compile(mdButton);
                if ((this.id.indexOf("content") != -1) && (content_to_ignore.indexOf(this.id) == -1)) {
                    $("#" + this.id).after(content($scope));
                }
            });
            $(".report-new-section-btn").last().css('margin-bottom', '15px');
        }

        function addNewSection(id, title) {
            var pre_id_txt = getUniqueID(title);
            var sectionHtml = getHTMLForNewSection(pre_id_txt, title);
            $("#" + id).after(sectionHtml);
            return pre_id_txt;
        }

        function getUniqueID(txt) {
            var pre_id_txt = txt.replace(" ", "-");
            var id = pre_id_txt + '-content';
            if ($("#" + id).length > 0) {
                return getUniqueID(pre_id_txt + "-" + Math.random().toString(36).substring(7));
            } else {
                return pre_id_txt;
            }
        }

        function getHTMLForNewSection(pre_id_txt, title) {
            title = title.capitalize();
            return '<div id="' + pre_id_txt + '-content" ' +
                'class="row editor-wrapper">' +
                '<div class="editor-title">' +
                '<h4>' + title + '</h4></div>' +
                '<div class="editor-body ckeditor-inline"' +
                ' contenteditable="true" ' +
                'id="' + pre_id_txt + '-inline">'
                + title + ' Section: Please write the ' + title + ' here...' +
                '<p>' + title + ' 1</p>' +
                '<p>' + title + ' 2</p>' +
                '<p>' + title + ' 3</p>' +
                '</div></div>';
        }

        function removeNewSectionBtn() {
            $(".report-new-section").remove();
        }

        function removeExistingFontSize(content) {
            content = content.replace("font-family", "kh-font-family");
            content = content.replace("font-size", "kh-font-size");
            return content
        }

        function filterHtml() {
            $("#trial-report table").removeAttr("style");
            $("#trial-report table").removeAttr("width");
            $("#trial-report table").removeAttr("height");

            $("#trial-report tr").removeAttr("style");
            $("#trial-report tr").removeAttr("width");
            $("#trial-report tr").removeAttr("height");

            $('#trial-report td').each(function () {
                if (!$(this).hasClass("kh-td-width")) {
                    $(this).removeAttr("style");
                    $(this).removeAttr("width");
                    $(this).removeAttr("height");
                    $(this).find("p").removeAttr("align");
                }
            });

            $('#trial-report table').each(function () {
                $(this).attr("cellspacing", 2);
                $(this).attr("cellpadding", 2);
            });
        }

        function filterTD() {
            $('#trial-report td').each(function () {
                $(this).children().each(function () {
                    $(this).removeAttr('width height style');
                });
                var fonts = $(this).find('font');
                fonts.contents().unwrap();
            });
        }

        function removeStyle() {
            $('#trial-report *').not("img").removeAttr("style");
            $("#trial-report a").each(function () {
                if ($(this).contents().length == 0) {
                    $(this).remove();
                } else {
                    $(this).contents().unwrap();
                }
            });
        }

        function overriteStyle() {
            $('#trial-report [style*="font-size"]').css('font-size', 'inherit');
            $('#trial-report [style*="font-family"]').css('font-family', 'inherit');
            $('#trial-report [style*="color"]').css('color', 'inherit');
            $('#trial-report [style*="background"]').css('background', 'inherit');
        }


        function removeHTMLTags(dataValue) {
            var tag_list = ['strong', 'b', 'i', 'u', 'em', 'cite', 's', 'strike', 'del', 'pre', 'mark', 'q', 'blockquote',
                'blink', 'marquee', 'small', 'big', 'caption', 'button', 'dd', 'dfn'];
            for (var i = 0; i < tag_list.length; i++) {
                dataValue = dataValue.replace("<" + tag_list[i] + ">", "<span>");
                dataValue = dataValue.replace("</" + tag_list[i] + ">", "</span>");
            }
            return dataValue;
        }


        return {
            CONSTANTS: CONSTANTS,
            centerExistingImg: centerExistingImg,
            findOkBtn: findOkBtn,
            filterTD: filterTD,
            filterHtml: filterHtml,
            addDeleteContentBtn: addDeleteContentBtn,
            removeReportDeleteBtn: removeReportDeleteBtn,
            addNewSectionBtn: addNewSectionBtn,
            removeNewSectionBtn: removeNewSectionBtn,
            getHTMLForNewSection: getHTMLForNewSection,
            addNewSection: addNewSection,
            setHeightWidthForImg: setHeightWidthForImg,
            makeCellsResizable: makeCellsResizable,
            removeExistingFontSize: removeExistingFontSize,
            removeStyle: removeStyle,
            removeHTMLTags: removeHTMLTags,
            setWidthInPercentage: setWidthInPercentage
        };

    }

})(angular);