(function (angular) {
    "use strict";
    angular.module("TrialApp")
        .factory('ReportFormlyFieldsHelper', ReportFormlyFieldsHelper);

    function ReportFormlyFieldsHelper() {
        return {
            getFields: _fields
        };

        function _fields($scope, constants) {
            return [
                {
                    className: "flex-33",
                    type: "searchable_select",
                    key: "subscription_package",
                    templateOptions: {
                        required: true,
                        label: "Subscription Package",
                        multiple: true,
                        labelProp: "name",
                        valueProp: "id",
                        options: constants.subscription_packages
                    }
                },
                {
                    className: "flex-33",
                    type: "searchable_select",
                    key: "category",
                    templateOptions: {
                        required: true,
                        multiple: true,
                        label: "Article Type",
                        labelProp: "title",
                        valueProp: "id",
                        options: getCategoryList(constants.categories)
                    }
                },
                {
                    className: "flex-33",
                    type: "searchable_select",
                    key: "article_type_fk",
                    templateOptions: {
                        required: true,
                        label: "Format",
                        labelProp: "title",
                        valueProp: "id",
                        options: getArticleTypeList(constants.article_types)
                    }
                },
                {
                    className: "flex-33",
                    type: "searchable_select",
                    key: "approvers",
                    templateOptions: {
                        required: true,
                        label: "Approvers",
                        multiple: true,
                        labelProp: "name",
                        valueProp: "value",
                        options: constants.team_admins
                    }
                },
                {
                    className: "flex-33",
                    type: "searchable_select",
                    key: "status",
                    templateOptions: {
                        label: "Status",
                        labelProp: "name",
                        valueProp: "value",
                        disabled: !$scope.can_publish(),
                        options: [{
                            name: "Draft",
                            value: 1
                        }, {
                            name: "Published",
                            value: 2
                        }]
                    },
                    expressionProperties: {
                        'templateOptions.disabled': function () {
                            if ($scope.article.approvers) {
                                return !($scope.article.approvers.indexOf(user_id) != -1);
                            }
                            else {
                                return false;
                            }
                        }
                    }
                },
                {
                    className: "flex-33",
                    type: "datepicker",
                    key: "publish_date",
                    templateOptions: {
                        placeholder: "dd/mm/yyyy",
                        format: "DD-MM-YYYY",
                        label: "Publish date",
                        disabled: !$scope.can_publish(),
                        maxDate: moment().toDate()
                    },
                    expressionProperties: {
                        'templateOptions.disabled': function () {
                            if ($scope.article.approvers) {
                                return !($scope.article.approvers.indexOf(user_id) != -1);
                            }
                            else {
                                return false;
                            }
                        }
                    }
                },
                {
                    className: "flex-33",
                    type: "searchable_select",
                    key: "region_list",
                    templateOptions: {
                        required: true,
                        multiple: true,
                        label: "Region",
                        labelProp: "name",
                        valueProp: "id",
                        options: constants.team_regions
                    }
                },
                {
                    className: "flex-33",
                    type: 'textarea',
                    key: 'description',
                    templateOptions: {
                        label: "Description",
                        rows: 1,
                        maxlength: 300
                    }
                },
                {
                    className: "flex-33",
                    type: "searchable_select",
                    key: "tag_list",
                    templateOptions: {
                        multiple: true,
                        labelProp: "title",
                        valueProp: "id",
                        label: "Tags",
                        options: constants.tags

                    }
                },

                {
                    className: "flex-33",
                    type: "searchable_select",
                    key: "commodities_list",
                    templateOptions: {
                        label: "Crops",
                        multiple: true,
                        labelProp: "title",
                        valueProp: "id",
                        options: constants.commodities
                    }
                },
                {
                    className: "flex-33",
                    type: "searchable_select",
                    key: "content_type_list",
                    templateOptions: {
                        label: "Technical categories",
                        multiple: true,
                        labelProp: "title",
                        valueProp: "id",
                        options: constants.content_types
                    }
                },
                {
                    className: "flex-33",
                    type: "searchable_select",
                    key: "author",
                    templateOptions: {
                        label: "Author(s)",
                        labelProp: "name",
                        multiple: true,
                        valueProp: "value",
                        options: constants.team_users
                    }
                },
                {
                    className: "flex-33",
                    type: "searchable_select",
                    key: "team",
                    templateOptions: {
                        label: "Share with Teams",
                        labelProp: "team_name",
                        multiple: true,
                        valueProp: "id",
                        options: constants.team_list
                    }
                },
                {
                    className: "flex-33",
                    type: "datepicker",
                    key: "expiry_date",
                    templateOptions: {
                        placeholder: "dd/mm/yyyy",
                        format: "DD-MM-YYYY",
                        label: "Expiry date",
                        minDate: moment().toDate()
                    }
                }
            ];

        }

        function getCategoryList(categories) {
            var filtered_categories = [];
            angular.forEach(categories, function (item) {
                switch (item.title) {
                    case "Trial Reports":
                    case "Trial Results":
                        filtered_categories.push({title: item.title, id: item.id});
                        break;
                    default:
                        break;
                }

            });
            return filtered_categories

        }

        function getArticleTypeList(article_types) {
            var filtered_article_types = [];
            angular.forEach(article_types, function (item) {
                switch (item.title) {
                    case "Memberreport":
                        filtered_article_types.push({title: "Agronomy Report", id: item.id});
                        break;
                    case "Rapidtrialreport":
                        filtered_article_types.push({title: "Variety Report", id: item.id});
                        break;
                    default:
                        break;
                }

            });
            return filtered_article_types

        }
    }


})
(angular);
