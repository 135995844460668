angular.module("TrialApp").controller("ReportLandingAddController",
    function ($location, $scope, $rootScope, ArticlesFactory, ArticlesReportFactory, ArticlesMetaSetupFactory, SubscriptionPackageFactory,
              $mdDialog, $filter, $khMessage, RegionsFactory, TeamsListFactory, TeamUsersFactory,
              GenericReportHelper, TrialAppHelper, CachingUtilsService) {
        $scope.report = {};
        $scope.dialogDict = GenericReportHelper.CONSTANTS.TEMPLATES.form;
        var ApplicationHelpers = applicationHelpers();
        ApplicationHelpers.initReport();

        var TrialReportAddForm = function (parentScope) {
            parentScope.locations = [];
            parentScope.trial_site_farms = [];
            return [
                {
                    className: "flex-100",
                    type: "select",
                    key: "article_type_fk",
                    templateOptions: {
                        valueProp: 'value',
                        labelProp: 'name',
                        placeholder: "Select report type",
                        required: true,
                        label: "Report Type",
                        options: [
                            {name: "Agronomy Report", value: "Memberreport"},
                            {name: "Variety Report", value: "Rapidtrialreport"}
                        ]
                    }
                },
                {
                    className: "flex-100",
                    type: "input",
                    key: "trial_code",
                    templateOptions: {
                        placeholder: "Enter trial code",
                        required: true,
                        label: "Trial Code",
                    }
                },
                {
                    className: "flex-100",
                    type: "input",
                    key: "title",
                    templateOptions: {
                        required: true,
                        label: "Report title",
                        placeholder: "Enter report title"
                    }
                },
                {
                    className: "flex-100",
                    type: "input",
                    key: "crop",
                    templateOptions: {
                        required: true,
                        placeholder: "Enter crop",
                        label: "Crop"
                    }
                },
                {
                    className: "flex-100",
                    type: "input",
                    key: "variety",
                    templateOptions: {
                        placeholder: "Enter variety",
                        label: "Variety"
                    }
                },
                {
                    className: "flex-100",
                    key: "centers",
                    type: "input",
                    templateOptions: {
                        placeholder: "Enter trial centre",
                        label: "Trial Centre",
                        required: true,
                    },
                },

                {
                    className: "flex-100",
                    key: "farm",
                    type: "input",
                    templateOptions: {
                        required: true,
                        placeholder: "Enter trial location",
                        label: "Trial Location",
                    },
                }
            ];
        };

        $scope.TrialReportAddForm = TrialReportAddForm($scope);


        $scope.saveReport = function () {
            ApplicationHelpers.initArticleData();
            ApplicationHelpers.saveArticle();
        };

        function applicationHelpers() {
            return {

                initReport: function () {
                    ApplicationHelpers.getReportConstants();
                    $scope.current_readable_date = TrialAppHelper.currentReadableDate();
                },

                getReportConstants: function () {
                    $scope.meta = {
                        teamusers: CachingUtilsService.addFactoryWithoutCache(TeamUsersFactory),
                        teams_list: CachingUtilsService.addFactoryWithoutCache(TeamsListFactory),
                        team_regions: CachingUtilsService.addFactoryWithoutCache(RegionsFactory),
                        subscription_packages: CachingUtilsService.addFactoryWithoutCache(SubscriptionPackageFactory),
                        categories: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, "categories", "article_categories"),
                        commodities: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, "commodities", "article_commodities"),
                        tags: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, "tags", "article_tags"),
                        content_type: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, "content-type", "article_content_type"),
                        article_type: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, "article-type", "article_article_type")
                    };
                },

                initArticleData: function () {
                    $scope.article = {};
                    $scope.article.title = $scope.report.title;
                    $scope.article.content_code = $scope.report.trial_code;
                    $scope.article.team = [team_id];
                    $scope.article.status = 1;
                    $scope.article.article_type_fk = $scope.report.article_type_fk;
                    $scope.article.category = ApplicationHelpers.getCategoryList();
                    $scope.article.region_list = ApplicationHelpers.getRegionList();
                    $scope.article.subscription_package = ApplicationHelpers.getSubscriptionPackages();
                    var article_content = document.getElementById("trial-report");
                    $scope.article.content = article_content.outerHTML;
                },

                getSubscriptionPackages: function () {
                    var package_list = [];
                    angular.forEach($scope.meta.subscription_packages, function (package_name) {
                        switch (package_name.name) {
                            case "Agronomy Consultancy":
                            case "Agronomy Results":
                            case "Agronomy Plus":
                            case "Agronomy Local":
                            case "Bespoke Agronomy":
                                package_list.push(package_name.id);
                                break;
                            default:
                                break;
                        }
                    });
                    return package_list;
                },

                getCategoryList: function () {
                    var category_list = [];
                    angular.forEach($scope.meta.categories, function (category) {
                        if (category.title == "Trial Reports") {
                            return category_list.push(category.id);
                        }
                    });
                    return category_list;
                },

                getRegionList: function () {
                    var category_list = [];
                    angular.forEach($scope.meta.team_regions, function (region) {
                        if (region.name == "National") {
                            return category_list.push(region.id);
                        }
                    });
                    return category_list;
                },

                saveArticle: function () {
                    angular.forEach($scope.meta.article_type, function (key, value) {
                        if (key.title == $scope.article.article_type_fk) {
                            $scope.article.article_type_fk = key.id
                        }
                    });
                    $khMessage.show($filter('translate')('report.message.save.info'), 10000);
                    ArticlesFactory.save($scope.article, function (response) {
                        var success_data = response.data;
                        $scope.$broadcast("responseSaved", success_data, {data_type: "new"});
                        $khMessage.show($filter('translate')('Saved successfully'), 10000);
                        $mdDialog.hide(success_data);
                    }, function (error_data) {
                        $khMessage.show($filter('translate')('Failed to save'), 10000);
                    });
                }
            };
        }

    }
);
