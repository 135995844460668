angular.module("ProfileApp").controller("EditProfileController", ["$scope", "ProfileFactory", "$location", "countries", "$khMessage", "$mdDialog",
    "$rootScope",
    function ($scope, ProfileFactory, $location, countries, $khMessage, $mdDialog, $rootScope) {
        var profile;
        ProfileFactory.get(function (result) {
            delete result['picture'];
            $scope.profile_data = result;
            if ($scope.profile_data.mobile) {
                $scope.profile_data.country_code = $scope.profile_data.mobile.slice(0, $scope.profile_data.mobile.length - 10);
                $scope.profile_data.mobile = $scope.profile_data.mobile.replace($scope.profile_data.country_code, '');
            }
            profile = angular.copy($scope.profile_data);
            setUpForm();
        });

        $scope.inViewProfile = function () {
            $scope.permissions = permissions;
            if ($scope.permissions.is_superuser) {
                $scope.permission_to_show = "Super admin";
            } else if ($scope.permissions.is_site_admin) {
                $scope.permission_to_show = "Site admin"
            } else if ($scope.permissions.is_team_admin) {
                $scope.permission_to_show = "Team admin"
            }
            return true;
        };

        $scope.countries = countries;
        $scope.saveProfile = function () {
            delete $scope.profile_data["subscribed_organization"];
            delete $scope.profile_data["permission"];
            delete $scope.profile_data["secondary_team"];
            delete $scope.profile_data["list_of_applications"];
            delete $scope.profile_data["subscription_package"];

            angular.forEach($scope.profile_data, function (value, key) {
                if (!value && profile[key] === $scope.profile_data[key]) {
                    delete $scope.profile_data[key];
                }
            });
            if ($scope.profile_data["mobile"] && $scope.profile_data["country_code"]) {
                $scope.profile_data["mobile"] = $scope.profile_data["country_code"] + $scope.profile_data["mobile"];
            }

            $scope.profile_data.$update({user_slug: $scope.profile_data.user_slug}, function (result) {
                $scope.profile_data = result;
                $khMessage.show("Saved successfully!");
                $rootScope.$broadcast("profile_edited");
                $mdDialog.hide();
            }, function (error) {
            });
        };
        $scope.$watch("profile_data.country", function (newval, oldval) {
            if (newval || oldval) {
                _.find($scope.countries, function (val) {
                    if (val.value === $scope.profile_data.country) {
                        $scope.profile_data["country_code"] = val.dial_code;
                    }
                })
            }
        });

        function setUpForm() {
            $scope.edit_profile_form = [
                {
                    "className": "flex-100",
                    "key": "bio",
                    "type": "textarea",
                    "templateOptions": {
                        "label": "About me",
                        required: false
                    }
                },
                {
                    "className": "flex-33",
                    "key": "gender",
                    "type": "searchable_select",
                    "templateOptions": {
                        "label": "Gender",
                        "options": [
                            {
                                name: "Male",
                                value: "M"
                            },
                            {
                                name: "Female",
                                value: "F"
                            }
                        ]
                    }
                },
                {
                    "className": "flex-33",
                    "key": "company_name",
                    "type": "input",
                    "templateOptions": {
                        "label": "Company name",
                        required: false
                    }
                },
                {
                    "className": "flex-33",
                    "key": "job_title",
                    "type": "input",
                    "templateOptions": {
                        "label": "Job title",
                        required: false
                    }
                },
                {
                    "className": "flex-100",
                    "key": "profession",
                    "type": "searchable_select",
                    "templateOptions": {
                        "label": "Profession",
                        "options": [
                            {
                                name: "Agronomist",
                                value: "Agronomist"
                            },
                            {
                                name: "Grower",
                                value: "Grower"
                            },
                            {
                                name: "Staff",
                                value: "Staff"
                            }
                        ]
                    }
                },
                {
                    "className": "flex-30",
                    "key": "country",
                    "type": "searchable_select",
                    "templateOptions": {
                        "label": "Country",
                        "labelProp": "fullname",
                        "valueProp": "value",
                        "options": $scope.countries
                    }
                },
                {
                    "className": "flex-20",
                    "key": "country_code",
                    "type": "input",
                    "templateOptions": {
                        "label": "Dial Code",
                        disabled: true
                    }
                },
                {
                    "className": "md-padding-right-left flex-45",
                    "key": "mobile",
                    "type": "input",
                    "templateOptions": {
                        "label": "Mobile",
                        helpText: "eg: 1234567890",
                        maxlength: 10,
                        minlength: 10,
                        required: false
                    }
                },
                {
                    "className": "flex-33",
                    "key": "url",
                    "type": "input",
                    "templateOptions": {
                        "label": "Website",
                        pattern: /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/,
                        helpText: "http://example.com",
                        required: false
                    }
                },
                {
                    "className": "flex-33",
                    "key": "linkedin_url",
                    "type": "input",
                    "templateOptions": {
                        label: "LinkedIn profile",
                        pattern: /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/,
                        helpText: "https://linkedin.com/",
                        required: false
                    }
                },
                {
                    "className": "flex-33",
                    "key": "twitter_account_name",
                    "type": "input",
                    "templateOptions": {
                        "label": "Twitter handle",
                        helpText: "@KisanHub",
                        required: false
                    }
                },

                {
                    "className": "flex-100",
                    /* Below is a special case handled where angularJs cannot copy file object, so using $ if you change
                    it handle it in PersonalProfileAPIViewSet. https://github.com/angular/angular.js/issues/12004 */
                    "key": "$picture",
                    "type": "file_upload",
                    "templateOptions": {
                        "label": "Upload profile picture",
                        "displayable_picture": $scope.profile_data['displayable_picture'],
                        required: false
                    }
                },
                {
                    "className": "flex-100",
                    "key": "is_public",
                    "type": "checkbox",
                    "templateOptions": {
                        "label": "Make profile public",
                        required: false
                    }
                },
                {
                    "className": "flex-100",
                    "key": "sharing_notification",
                    "type": "checkbox",
                    "templateOptions": {
                        "label": "Subscribe to Sharing Notifications"
                    }
                }
            ];
        }
    }]);
