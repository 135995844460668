/**
 * Cached user settings
 */
angular.module("UserSettingsApp").service('UserSettingsService', function($resource,
                                                                          $mdDialog,
                                                                          DateTimeHelperFactory) {
    
    var self=this;
    var subject$ = new Rx.Subject();
    var resource = $resource('api/v1.0/profiles/user-settings/');

    /**
     * Return a subject that can be used to subscribe to settings changes
     * @return {Rx.Subject}
     */
    self.get$ = function() {
        return subject$;
    };

    /**
     * Update the user settings
     * @param vals
     * @return {*}
     */
    self.update = function(vals) {
        updateAndConvert(vals);
        return resource.save(self, function (result) {
            subject$.onNext(self);
        }).$promise;
    };

    /**
     * Reload the settings from the server
     */
    self.reload = function() {

    };

    function reload() {
        self.promise = resource.get(updateAndConvert).$promise;
    }

    /**
     * Update the local cache and convert date formats to moment format
     * @param result
     */
    function updateAndConvert(result) {
        _.extend(self, result);
        if (result.DEFAULT_USER_READABLE_DATE_FORMAT) {
            self.dateFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment(
                result.DEFAULT_USER_READABLE_DATE_FORMAT);
        } else {
            self.dateFormat = 'DD MMM, YYYY';
        }
        if (result.DEFAULT_USER_READABLE_TIME_FORMAT) {
            self.timeFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment(
                result.DEFAULT_USER_READABLE_TIME_FORMAT);
        } else {
            self.timeFormat = 'HH:mm';
        }
        if (result.DEFAULT_USER_READABLE_DATE_TIME_FORMAT) {
            self.dateTimeFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment(
                result.DEFAULT_USER_READABLE_DATE_TIME_FORMAT);
        } else {
            self.dateTimeFormat = 'DD MMM, YYYY HH:mm';
        }
    }


    self.showEditDialog = function (event) {
        $mdDialog.show({
            templateUrl: '/static/assets/khapps/settings_v2/general/user_settings/user-settings-dialog.html',
            targetEvent: event,
            parent: angular.element(document.body),
            clickOutsideToClose: false,
            fullscreen: true
        });
    };

    reload();
});