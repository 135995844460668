angular.module('TrialApp').controller('ReportViewController',
    function ($location, $sce, $scope, $rootScope, $routeParams, $mdDialog, ArticlesFactory, ArticlesReportFactory,
              article_slug, ArticleUtilsService) {
        var count_setinterval = 0;

        $scope.subscription_status = {};
        $scope.subscription_status.data = {};
        $scope.subscription_status.data.is_trialing = false;
        $scope.show_secondary_nav_filters = false;

        ArticlesFactory.get({slug: article_slug}, function (result) {
            $scope.article = result.data;
            getArticlesForFilters({category: $scope.article.category[0]});
            $scope.article.content = $sce.trustAsHtml($scope.article.content);
            ArticleUtilsService.ArticleHelpers().disable_editable_divs();
            if ($scope.article.article_type_fk === 'Rapidtrialreport') {
                $scope.set_interval = setInterval(function () {
                    ArticleUtilsService.ArticleHelpers().disable_editable_divs();
                    count_setinterval++;
                    check_count_of_setinterval();
                }, 5000);
            }
        });
        $scope.displayOnlyPDF = function (file) {
            if (file) {
                return file.substr(file.lastIndexOf('.') + 1);
            }
        };

        $scope.editArticle = function () {
            window.open('/dashboard/trial/#/report/' + $scope.article.slug, '_blank');
        };

        $scope.trustContentAsHtml = function (content) {
            return $sce.trustAsHtml(content);
        };
        // check for permissions
        $scope.checkIfAuthor = function (data) {
            return false;
        };
        function equalise() {
            var maxHeight = 0;
            $('div.related-article-card').each(function () {
                if ($(this).height() > maxHeight) {
                    maxHeight = $(this).height();
                }
            });
            $('div.related-article-card-inner').height(maxHeight);
        }

        // Edit article helper
        $scope.EditArticleHelper = function (slug) {
            return ArticleUtilsService.ArticleHelpers().editArticle($location, slug);
        };

        $scope.trustUrl = function (file) {
            return $sce.trustAsResourceUrl('https://docs.google.com/viewer?url=' + file + '&embedded=true');
        };
        function getArticlesForFilters(filtersDictionary) {
            ArticlesFactory.query({filters: filtersDictionary}, function (result) {
                $scope.related_articles = result.data;

                if (result.data.length > 0) {
                    setTimeout(equalise, 4000);
                    if (!ie_11) {
                        ArticleUtilsService.addFlex('md-card');
                    }
                }
            });
        }

        $scope.get_article_edit_link = function () {
            if ($scope.article.article_type_fk_readable === 'Memberreport' ||
                $scope.article.article_type_fk_readable === 'Rapidtrialreport') {
                window.open('/dashboard/trial/#/report/' + $scope.article.slug, '_blank');
            } else {
                $location.path('/edit/' + $scope.article.slug);
            }
        };

        $scope.get_client_icon = function () {
            if (tenant_id == 'niab') {
                return '/static/assets/khapps2/base/khutils/images/articles/niabtag.png';
            } else {
                return 'favicon.ico';
            }
        };

        function check_count_of_setinterval() {
            if (count_setinterval > 2) {
                clearInterval($scope.set_interval);
            }
        }

        $scope.checkForExportAsPdf = function (article) {
            return (article.article_type_fk_readable === 'Memberreport' 
                || article.article_type_fk_readable === 'Rapidtrialreport');
        };

        $scope.cancel = function () {
            $mdDialog.hide();
        };
        $scope.showRelatedArticle = function (article_slug) {
            $mdDialog.hide(article_slug);
        };
    }

);
