export default angular.module('SatBookApp', [
            'KhBase',
            'KhChart',
            'leaflet-directive',
            'khUtils',
            'ApiUtils.ksat',
            'ApiUtils.fms.farmfield',
            'ApiUtils.fms.fieldplotcrop',
            'ApiUtils.khutils.ui',
        ]);

