angular.module("TeamsApp").controller('TeamMembersController', ["$scope", "$rootScope", "team_slug", "$mdDialog", "TeamMembersFactory", "ProfileFactory", "$khMessage",
    function ($scope, $rootScope, team_slug, $mdDialog, TeamMembersFactory, ProfileFactory, $khMessage) {
        TeamMembersFactory.get({team_slug: team_slug}, function (result) {
            $scope.team = result;
            if ($scope.team.list_of_users_in_team.length < 1) {
                $mdDialog.cancel();
                $khMessage.show("There are no members in '" + $scope.team.team_name + "' team!", 5000);
            }
        });

        ProfileFactory.get(function (result) {
            $scope.user_profile = result;
        });

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.activateDeactivateUser = function (index) {
            var username = $scope.team.list_of_users_in_team[index].username;
            var status = !$scope.team.list_of_users_in_team[index].is_active;
            if (status) {
                var deactivateConfirm = $mdDialog.confirm()
                    .title('Deactivate user')
                    .textContent('Are you sure you want to deactivate the user?')
                    .ariaLabel('Deactivate user')
                    .ok('Deactivate')
                    .cancel('Cancel');
                $mdDialog.show(deactivateConfirm).then(function () {
                    window.location = "/profiles/viewteam/" + username + "/deactivate_user/";
                }, function () {
                    $scope.team.list_of_users_in_team[index].is_active = !$scope.team.list_of_users_in_team[index].is_active;
                });
            }
            else {
                var activateConfirm = $mdDialog.confirm()
                    .title('Activate user')
                    .textContent('Are you sure you want to activate the user?')
                    .ariaLabel('Activate user')
                    .ok('Activate')
                    .cancel('Cancel');
                $mdDialog.show(activateConfirm).then(function () {
                    window.location = "/profiles/viewteam/" + username + "/activate_user/";
                }, function () {
                    $scope.team.list_of_users_in_team[index].is_active = !$scope.team.list_of_users_in_team[index].is_active;
                });
            }
        }
    }]);