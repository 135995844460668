angular.module("TrialApp")
    .controller("TrialReportLandingController",
        function AssessmentAddEditController($location, $scope, $rootScope, $filter, $routeParams, $compile, PlatformSettingsFactory,
                                             NgTableParams, ArticlesReportFactory, $mdDialog, ArticlesFactory,
                                             GenericReportHelper, TrialAppHelper, GenericUtilsService) {

            const All = 'All';
            const MEMBER_REPORT = "Agronomy Report";
            const RAPID_REPORT = "Variety Report";

            var AppHelpers = appHelpers();
            $rootScope.page_id = "trial_reports";
            $scope.trialCard = GenericReportHelper.CONSTANTS.TEMPLATES.cards;
            $scope.article_type = 'Rapidtrialreport';
            $scope.reportStatusList = [
                {id: "", title: All},
                {id: 'Draft', title: 'Draft'},
                {id: 'Published', title: 'Published'}
            ];
            $scope.reportArticleTypeList = [
                {id: "", title: All},
                {
                    id: MEMBER_REPORT,
                    title: MEMBER_REPORT
                },
                {
                    id: RAPID_REPORT,
                    title: RAPID_REPORT
                }
            ];
            $scope.tableFilters = {
                title: TrialAppHelper.getFilterDict('report', 'title'),
                article_type: TrialAppHelper.getFilterDict('report', 'article_type_display', 'article_type', 'select'),
                trial_code: TrialAppHelper.getFilterDict('report', 'content_code', 'trial_code'),
                status: TrialAppHelper.getFilterDict('report', 'get_status_display', 'status', 'select'),
                publish_date: TrialAppHelper.getFilterDict('report', 'readable_publish_date', 'publish_date')
            };

            var updated_selected_harvest_year = $rootScope.$on("updated_selected_harvest_year",
                function () {
                    $scope.callReports();
                }
            );

            $scope.$on('$destroy', function () {
                updated_selected_harvest_year();
            });

            $scope.editReport = function (report_slug) {
                $rootScope.report_slug = report_slug;
                $mdDialog.show({
                    controller: "ReportEditController",
                    templateUrl: "/static/assets/khapps/trial/partials/includes/vm_trial_stepwizard_dialog.html",
                    locals: {
                        report_slug: report_slug
                    },
                    controllerAs: "dl",
                    parent: angular.element(document.body),
                    skipHide: true,
                    multiple: true
                }).then(function (response_data) {
                    updateTable(response_data)
                });
            };

            $scope.callReports = function () {
                ArticlesReportFactory.query({
                        content: "trials",
                        publish_year: $rootScope.selected_harvest_year
                    },
                    function (success_data) {
                        AppHelpers.buildToggleReports(success_data);
                        if ($routeParams.article_slug) {
                            $scope.editReport($routeParams.article_slug);
                        }
                    }
                )
            };


            $scope.viewReport = function (article_slug) {
                $mdDialog.show({
                    controller: "ReportViewController",
                    templateUrl: '/static/assets/khapps/articles/articles/partials/SingleArticle.html',
                    parent: angular.element(document.body),
                    locals: {
                        article_slug: article_slug
                    },
                    escapeToClose: false,
                    clickOutsideToClose: false
                }).then(function () {
                    //pass
                });
            };


            $scope.softDeleteReport = function (slug, title) {
                var confirm = $mdDialog.confirm().title($filter('translate')('common.areYouSure')).textContent($filter('translate')('report.message.remove.warning') + title).ariaLabel('Delete Report').ok($filter('translate')('common.yes')).cancel($filter('translate')('common.no'));
                $mdDialog.show(confirm).then(function () {
                    ArticlesFactory.delete({slug: slug},
                        function (success_data) {
                            AppHelpers.updateReportList(slug);
                        },
                        function (error_data) {
                            if (error_data.status && error_data.status == 403) {
                                $mdDialog.show(
                                    $mdDialog.alert().clickOutsideToClose(true).title('Request forbidden.').textContent('You do not have permission to delete this Protocol.').ariaLabel('Alert').ok('Ok')
                                );
                            }
                        });

                }, function () {
                    //pass
                });
            };

            $scope.changeStatus = function (report_slug) {
                $scope.dialogDict = GenericReportHelper.CONSTANTS.EDIT_TEMPLATES.statusForm;
                $rootScope.report_slug = report_slug;
                TrialAppHelper.getMdDialogForm($mdDialog, $scope, {
                    controller: "ReportStatusChangeController",
                    slug: {article_slug: report_slug}
                });
            };

            $scope.$on("responseSaved", function (broadcast, success_data, dict) {
                updateTable(success_data)
            });

            function updateTable(success_data) {
                if (success_data && success_data.slug) {
                    success_data = TrialAppHelper.getReadableDateFormats(success_data, ['publish_date']);
                    AppHelpers.addReadableArticleType(success_data);
                    var objectUpdated = TrialAppHelper.updateObjectInList($scope.allReports, "slug", success_data, AppHelpers.getNgTable);
                    if (!objectUpdated) {
                        ArticlesFactory.get({slug: success_data.slug}, function (success_data) {
                            success_data = TrialAppHelper.getReadableDateFormats(success_data.data, ['publish_date']);
                            AppHelpers.addReadableArticleType(success_data);
                            if (GenericUtilsService.isEmpty($scope.allReports)) {
                                $scope.allReports = [];
                            }
                            $scope.allReports.splice(0, 0, success_data);
                            AppHelpers.getNgTable()
                        }, function (error_data) {

                        });
                    }
                }
            }

            $rootScope.$on("addReport", function (broadcast, success_data, dict) {
                $scope.editReport(success_data.slug);
            });

            function appHelpers() {
                return {
                    getNgTable: function () {
                        $scope.tableParams = new NgTableParams({count: 50}, {dataset: $scope.allReports});
                        $scope.showEmptyMessage = ($scope.allReports.length == 0);
                    },

                    buildToggleReports: function (success_data) {
                        for (var i = 0; i < success_data.length; i++) {
                            AppHelpers.addReadableArticleType(success_data[i]);
                        }
                        TrialAppHelper.getReadableDateFormats(success_data, ['publish_date']);
                        $scope.allReports = success_data;
                        AppHelpers.getNgTable();
                    },

                    addReadableArticleType: function (success_data) {
                        switch (success_data.article_type_fk) {
                            case "Memberreport":
                                success_data.article_type_display = MEMBER_REPORT;
                                break;
                            case "Rapidtrialreport":
                                success_data.article_type_display = RAPID_REPORT;
                                break;
                            default:
                                success_data.article_type_display = "Report"
                        }
                        if (!success_data.hasOwnProperty("get_status_display")) {
                            if (success_data.status == 1) {
                                success_data.get_status_display = "Draft";
                            } else {
                                success_data.get_status_display = "Published";

                            }
                        }
                    },
                    updateReportList: function (slug) {
                        for (var i = 0; i < $scope.allReports.length; i++) {
                            if ($scope.allReports[i].slug == slug) {
                                $scope.allReports.splice(i, 1)
                            }
                        }
                        $scope.tableParams.reload();
                    }
                }
            }

            $scope.callReports();
        });

