(function (angular) {
    "use strict";
    angular.module("TrialApp")
        .controller("ReportEditController", function ReportEditController($scope, $rootScope, $routeParams,
                                                                          GenericReportHelper, ReportFormlyFieldsHelper,
                                                                          ArticlesFactory, ArticlesReportFactory,
                                                                          $filter, $mdDialog, fileUploads, $khMessage,
                                                                          ReportEditHelper, TrialAppHelper,
                                                                          ArticleConstantsResource, ArticleUtilsService,
                                                                          GenericUtilsService) {
            var vm = this;
            const MEMBER_REPORT = "Memberreport";
            vm.dialogDict = GenericReportHelper.CONSTANTS.EDIT_TEMPLATES.form;
            $scope.article = {};
            var article_slug = $rootScope.report_slug;
            $scope.imageuploadsconfig = ReportEditHelper.CONSTANTS.imageuploadsconfig;

            ArticleConstantsResource.query(function (sucess_data) {
                vm.article_constants = sucess_data.data;
                getArticle();
            });

            function getArticle() {
                ArticlesFactory.get({slug: article_slug, type: 'edit'}, function (success_data) {
                    $scope.article = ApplicationHelpers.filterArticleEditData(success_data.data);
                    $scope.article_type_fk = $scope.article.article_type_fk_readable;
                    $scope.current_readable_date = TrialAppHelper.currentReadableDate();
                    if ($routeParams.s == "approve" && $routeParams.article_slug) {
                        var message;
                        if (!$scope.can_publish()) {
                            message = "You do not have permission to approve the report.";
                        } else {
                            message = "To approve and publish the report: change the status of the report from Draft to Publish in Subscription Packages and Approval section.";
                        }
                        $mdDialog.show(
                            $mdDialog.alert({
                                skipHide: true,
                                multiple: true,
                                parent: angular.element(document.body)
                            }).clickOutsideToClose(true).textContent(message).ariaLabel('Alert').ok('Ok')
                        );
                    }
                    ApplicationHelpers.parseArticleContent();
                    ApplicationHelpers.getRelatedReports();
                    ReportEditHelper.addDeleteContentBtn($scope);
                    ReportEditHelper.addNewSectionBtn($scope);
                    vm.ReportAddEditFormFields = ReportFormlyFieldsHelper.getFields($scope, vm.article_constants);
                }, function (error_data) {
                    var message = $filter('translate')('common.api_retrieve_failed');
                    $mdDialog.show(
                        $mdDialog.alert({
                            skipHide: true,
                            multiple: true,
                            parent: angular.element(document.body)
                        }).clickOutsideToClose(true).textContent(message).ariaLabel('Alert').ok('Ok')
                    );
                });
            }

            CKEDITOR.on('dialogDefinition', function (ev) {
                ev.stop();
                var dialogName = ev.data.name;
                var dialogDefinition = ev.data.definition;
                if (dialogName == 'table') {
                    var info = dialogDefinition.getContents('info');
                    info.get('txtCellSpace')['default'] = '2';
                    info.get('txtCellPad')['default'] = '2';
                    info.get('txtBorder')['default'] = '0';
                }
            });

            CKEDITOR.on('instanceReady', function (ev) {
                ev.stop();
                ReportEditHelper.centerExistingImg();
                ReportEditHelper.makeCellsResizable();
                $(".cke_button__image_icon").on("click", function () {
                    setTimeout(ReportEditHelper.findOkBtn, 2000);
                });

                $("#trial-report img").on('mouseover', function () {
                    if (!this.parentElement.getAttribute("align")) {
                        $(this).replaceWith("<p align='center'>" + this.outerHTML + "</p>");
                    }
                    ReportEditHelper.setHeightWidthForImg()
                });
                $("#report-variety").css("border", "1px dashed grey");
                ev.editor.on('paste', function (evt) {
                    if (evt.data.dataValue) {
                        evt.data.dataValue = ReportEditHelper.removeHTMLTags(evt.data.dataValue);
                        evt.data.dataValue = evt.data.dataValue.replace("font-family", "kh-font-family");
                        evt.data.dataValue = evt.data.dataValue.replace("font-size", "kh-font-size");
                        evt.data.dataValue = evt.data.dataValue.replace("<table", "<table cellspacing='2' cellpadding='2'");
                        setTimeout(ReportEditHelper.filterHtml, 100);
                        setTimeout(ReportEditHelper.filterTD, 100);
                    }

                    ReportEditHelper.removeStyle();
                    ReportEditHelper.makeCellsResizable();
                    if (evt.data.dataValue.startsWith("<img")) {
                        evt.data.dataValue = "<p align='center'>" + evt.data.dataValue + "</p>";
                        setTimeout(ReportEditHelper.centerExistingImg, 1000);
                        setTimeout(ReportEditHelper.setHeightWidthForImg, 1000);
                    }
                });
            });

            var interval = setInterval(function () {
                ReportEditHelper.centerExistingImg();
                ReportEditHelper.removeStyle();
            }, 3000);


            $scope.saveReport = function (save_and_continue) {
                ReportEditHelper.removeReportDeleteBtn();
                ReportEditHelper.removeNewSectionBtn();
                $("#report-variety").css("border", "");
                $(".td-grip").remove();
                var article_content = document.getElementById("trial-report");
                ReportEditHelper.setWidthInPercentage();
                article_content = article_content.outerHTML;
                $scope.article.content = article_content.replace(/[\u200B-\u200D\uFEFF]/g, '');
                $scope.article.title = $.trim($("#report-title").text());
                $scope.article.content_code = $.trim($("#report-trial-code").text());
                ApplicationHelpers.updateArticle(save_and_continue);
            };

            $scope.addSection = function (id) {
                $mdDialog.show({
                    controller: "NewSectionAddController",
                    templateUrl: "/static/assets/khapps/trial/apps/report_dashboard/member_rapid/supporting_apps/newsection/NewSectionForm.html",
                    parent: angular.element(document.body),
                    skipHide: true,
                    multiple: true
                }).then(function (response_data) {
                    var new_id = ReportEditHelper.addNewSection(id, response_data.title);
                    ReportEditHelper.addNewSectionBtn($scope);
                    ReportEditHelper.addDeleteContentBtn($scope);
                    CKEDITOR.inline(new_id + "-inline");
                });
            };

            $scope.previewReport = function () {
                ReportEditHelper.removeReportDeleteBtn();
                ReportEditHelper.removeNewSectionBtn();
                var article_content = document.getElementById("trial-report");
                article_content = article_content.outerHTML;
                var content = article_content.replace(/[\u200B-\u200D\uFEFF]/g, '');
                var article_header_content = document.getElementById("report-header-html");
                $mdDialog.show({
                    controller: 'ReportPreviewController',
                    skipHide: true,
                    multiple: true,
                    templateUrl: '/static/assets/khapps/trial/apps/report_dashboard/member_rapid/report/report_preview.html',
                    locals: {
                        preview_article: {
                            content: content.toString(),
                            header: article_header_content.outerHTML
                        }
                    },
                    parent: angular.element(document.body),
                    clickOutsideToClose: false,
                    escapeToClose: false
                }).then(function () {
                    ReportEditHelper.addDeleteContentBtn($scope);
                    ReportEditHelper.addNewSectionBtn($scope);
                });
            };


            vm.cancel = function (success_data) {
                if (GenericUtilsService.isEmpty(success_data)){
                    success_data = $scope.article;
                }
                var confirm = $mdDialog.confirm({
                    skipHide: true,
                    multiple: true,
                    parent: angular.element(document.body)
                })
                    .title($filter('translate')('common.areYouSure'))
                    .textContent("Are you sure you want to close report ?")
                    .ariaLabel('close ')
                    .ok($filter('translate')('common.yes'))
                    .cancel($filter('translate')('common.no'));

                $mdDialog.show(confirm).then(function () {
                    $mdDialog.hide(success_data);
                }, function () {
                    ReportEditHelper.addDeleteContentBtn($scope);
                    ReportEditHelper.addNewSectionBtn($scope);
                    ReportEditHelper.makeCellsResizable();
                    $("#report-variety").css("border", "1px dashed grey");
                });
            };

            $scope.cancelReport = function () {
                vm.cancel();
            };

            $scope.get_client_icon = function () {
                if (client_name == 'niab') {
                    return '/static/assets/khapps2/base/khutils/images/articles/niabtag.png';
                } else {
                    return 'favicon.ico';
                }
            };

            $scope.can_publish = function () {
                if ($scope.article.approvers) {
                    return ($scope.article.approvers.indexOf(user_id) != -1);
                } else {
                    return true;
                }
            };
            $scope.removeDiv = function (div) {
                var title = ApplicationHelpers.capitalizeFirstLetter(div.split("-")[0]);

                var confirm = $mdDialog.confirm({
                    skipHide: true,
                    multiple: true,
                    parent: angular.element(document.body)
                })
                    .title($filter('translate')('common.areYouSure'))
                    .textContent("Are you sure you want to delete " + title + " section")
                    .ariaLabel('Delete ' + title)
                    .ok($filter('translate')('common.yes'))
                    .cancel($filter('translate')('common.no'));

                $mdDialog.show(confirm).then(function () {
                    ApplicationHelpers.removeDivById(div);
                });
            };

            var ApplicationHelpers = {
                filterArticleEditData: function (success_data) {
                    success_data.publish_date = new Date(success_data.publish_date);

                    fileUploads.displayExistingImages(success_data.featured_image, $scope.imageuploadsconfig["db_type"]);
                    return success_data;
                },

                updateArticle: function (save_and_continue) {
                    $scope.article.featured_image = fileUploads.saveImages();
                    $khMessage.show($filter('translate')('report.message.save.info'), 10000);
                    ArticlesFactory.update($scope.article, function (success_data) {
                        $khMessage.show($filter('translate')('report.message.update.success'), 10000);
                        if (!save_and_continue) {
                            clearInterval(interval);
                            vm.cancel(success_data.data);
                        } else {
                            ReportEditHelper.addDeleteContentBtn($scope);
                            ReportEditHelper.addNewSectionBtn($scope);
                            ReportEditHelper.makeCellsResizable();
                            $("#report-variety").css("border", "1px dashed grey");
                        }
                    }, function (error) {
                        $khMessage.show($filter('translate')('report.message.update.error'), 10000);

                    });
                },

                initiateCkeditor: function () {
                    for (var i = 0; i < $(".ckeditor-inline").length; i++) {
                        CKEDITOR.inline($(".ckeditor-inline")[i].id);
                    }
                },

                parseArticleContent: function () {
                    var wrapper = document.createElement('div');
                    wrapper.innerHTML = ReportEditHelper.removeExistingFontSize($scope.article.content);
                    $("#article-add-edit-content").html(wrapper.firstChild);
                    ApplicationHelpers.initiateCkeditor();
                },


                getRelatedReports: function () {
                    if ($scope.article_type_fk == MEMBER_REPORT) {
                        if ($("#references-inline").length != 1) {
                            ArticlesReportFactory.get({
                                report_slug: $scope.article.report_slug, article_type: MEMBER_REPORT
                            }, function (success_data) {
                                $scope.member_reports = success_data;
                                ArticleUtilsService.ArticleHelpers().generate_and_add_references_table($scope.member_reports, article_slug);
                                ReportEditHelper.addDeleteContentBtn($scope);
                            }, function (error_data) {
                            });
                        }
                    }
                },

                capitalizeFirstLetter: function (s) {
                    return s[0].toUpperCase() + s.slice(1);
                },
                removeDivById: function (id) {
                    try {
                        var elem = document.getElementById(id);
                        elem.parentNode.removeChild(elem);
                        ReportEditHelper.addNewSectionBtn($scope);
                        return true;
                    } catch (err) {
                        return false
                    }
                }
            }


        });

})(angular);
