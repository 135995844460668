angular.module("TeamsApp").controller("TeamSettingsController", ["$scope", "TeamSettingsFactory", "$mdDialog", "$khMessage",
    function ($scope, TeamSettingsFactory, $mdDialog, $khMessage) {
        TeamSettingsFactory.get(function (result) {
            $scope.team_setting = {};
            $scope.team_setting["time_zone"] = result["TIME_ZONE"];
            $scope.team_setting["date_format"] = result["DATE_FORMAT"];
            $scope.team_setting["currency"] = result["CURRENCY"];
        });
        $scope.updateTeamSettings = function (team_setting) {
            TeamSettingsFactory.update(team_setting, function (result) {
                $khMessage.show("Updated successfully!");
                $mdDialog.hide();
            })
        };
        setUpForm();
        function setUpForm() {
            $scope.team_settings_form = [
                {
                    "className": "flex-100",
                    type: "searchable_select",
                    key: "time_zone",
                    templateOptions: {
                        required: true,
                        label: "Time zone",
                        labelProp: "key",
                        valueProp: "value",
                        options: [{
                            key: "Europe-London",
                            value: "Europe/London"

                        }]
                    }
                },
                {
                    "className": "flex-100",
                    type: "searchable_select",
                    key: "date_format",
                    templateOptions: {
                        required: true,
                        label: "Date format",
                        labelProp: "key",
                        valueProp: "value",
                        options: [{
                            key: "dd-mmm-yyyy",
                            value: "dd-mmm-yyyy"
                        }, {
                            key: "dd/mm/yy",
                            value: "dd/mm/yy"
                        }]
                    }
                },
                {
                    "className": "flex-100",
                    type: "searchable_select",
                    key: "currency",
                    templateOptions: {
                        required: true,
                        label: "Currency",
                        labelProp: "currency_name",
                        valueProp: "currency_value",
                        options: [{
                            currency_name: "Pound",
                            currency_value: "GBP"
                        }]
                    }
                }
            ];
        }
    }]);