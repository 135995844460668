angular.module("SettingsApp").controller("SettingsLandingPageController",
    function ($scope, $rootScope, $mdDialog) {
        $rootScope.page_id = "settings";
        $rootScope.sub_app_page_id = null;

        $scope.editProfile = function () {
            $mdDialog.show({
                controller: "EditProfileController",
                templateUrl: '/static/assets/khapps/settings_v2/general/profile/EditProfile.html',
                parent: angular.element(document.body),
                clickOutsideToClose: false
            })
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    });