angular.module("TrialApp").config(["$routeProvider", "$resourceProvider",
    function ($routeProvider, $resourceProvider) {
        $resourceProvider.defaults.stripTrailingSlashes = false;

        $routeProvider
            .when("/report/:article_slug", {
                controller: "TrialReportLandingController",
                templateUrl: "/static/assets/khapps/trial/partials/includes/trial_card.html"
            })

            .when("/report", {
                controller: "TrialReportLandingController",
                templateUrl: "/static/assets/khapps/trial/partials/includes/trial_card.html"
            })

            .otherwise({
                redirectTo: "/report"
            });

    }
]);
