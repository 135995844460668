angular.module("UserSettingsApp")
.component('userSettingsDialog', {
    templateUrl: '/static/assets/khapps/settings_v2/general/user_settings/user-settings-dialog.component.html',
    controller: function ($rootScope, $q,
                          SideNavFactory,
                          $mdDialog, FieldNamesFactory,
                          $khMessage, TeamsOfUserFactory,
                          CommunicationLanguageFactory,
                          UserSettingsService,
                          $resource,
                          DateTimeHelperFactory, FarmConstantsService) {

        var self=this;
        var availableFarms = null;
        var farmFetchPromise;

        /**
         * Fetch the list of possible farms for this team
         * @return {*}
         */
        function fetchAvailableFarms() {
            availableFarms = null;
            farmFetchPromise = $resource('/api/field/summary-by-farm/').query().$promise;

            farmFetchPromise.then(function(farms) {
                availableFarms = farms;
            });
            return farmFetchPromise;
        }

        /**
         * Fetch all the options in parallel
         */
        $q.all([SideNavFactory.query().$promise,
                TeamsOfUserFactory.query().$promise,
                UserSettingsService.promise,
                FarmConstantsService.promise]).then(function(results) {

            self.apps = results[0];
            self.all_teams = results[1];
            self.user_settings = results[2];
            self.planting_season_list = results[3].planting_season_list;
            $rootScope.default_user_team = self.user_settings.DEFAULT_USER_TEAM;
            fetchAvailableFarms();
            setUpForm();
            setupOperationUserSettingsForm();
        });

        self.date_format_dict = [
            {name: 'DD MMM, YYYY', value: '%d %b, %Y'},
            {name: 'MMM DD, YYYY', value: '%b %d, %Y'},
            {name: 'DD MMM, YYYY HH:mm', value: '%d %b, %Y %H:%M'},
            {name: 'MMM DD, YYYY HH:mm', value: '%b %d, %Y %H:%M'},
            {name: 'HH:mm', value: '%H:%M'},
            {name: 'HH:mm:ss', value: '%H:%M:%S'}
        ];

        self.default_things = {};

        self.updateUserSettings = function () {
            UserSettingsService.update(self.user_settings).then(function (result) {
                $mdDialog.hide(result);
                // legacy $rootScope
                setUserSettings(result);
                $khMessage.show('Updated successfully.', 3000);
            });
        };

        /**
         * Update the $rootScope with various settings
         * This is legacy - use this service directly
         * @param result
         */
        function setUserSettings(result) {
            $rootScope.user_settings = result;
            if (result.DEFAULT_USER_READABLE_DATE_FORMAT) {
                $rootScope.userSettingDateFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment(
                    result.DEFAULT_USER_READABLE_DATE_FORMAT);
            } else {
                $rootScope.userSettingDateFormat = 'DD MMM, YYYY';
            }
            if (result.DEFAULT_USER_READABLE_TIME_FORMAT) {
                $rootScope.userSettingTimeFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment(
                    result.DEFAULT_USER_READABLE_TIME_FORMAT);
            } else {
                $rootScope.userSettingTimeFormat = 'HH:mm';
            }
            if (result.DEFAULT_USER_READABLE_DATE_TIME_FORMAT) {
                $rootScope.userSettingDateTimeFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment(
                    result.DEFAULT_USER_READABLE_DATE_TIME_FORMAT);
            } else {
                $rootScope.userSettingDateTimeFormat = 'DD MMM, YYYY HH:mm';
            }
        }

        function setupOperationUserSettingsForm() {
            self.operation_user_settings_form = [
                {
                    className: 'flex-33',
                    key: 'OPERATION_STATUS',
                    type: 'select',
                    templateOptions: {
                        label: 'Status',
                        options: [{
                            name: 'Plan',
                            value: 'Plan'
                        }, {
                            name: 'Record',
                            value: 'Record'
                        }]
                    }
                },
                {
                    className: 'flex-33',
                    key: 'OPERATION_START_DATE',
                    type: 'select',
                    templateOptions: {
                        label: 'Start Date',
                        options: [{
                            name: 'None',
                            value: 'None'
                        }, {
                            name: 'Before Two Weeks',
                            value: 'Before Two Weeks'
                        }, {
                            name: 'Before One Week',
                            value: 'Before One Week'
                        }, {
                            name: 'Yesterday',
                            value: 'Yesterday'
                        }, {
                            name: 'Today',
                            value: 'Today'
                        }, {
                            name: 'Tomorrow',
                            value: 'Tomorrow'
                        }, {
                            name: 'After One Week',
                            value: 'After One Week'
                        }, {
                            name: 'After Two Weeks',
                            value: 'After Two Weeks'
                        }]
                    }
                },
                {
                    className: 'flex-33',
                    key: 'OPERATION_START_TIME',
                    type: 'input',
                    templateOptions: {
                        type: 'text',
                        label: 'Start Time',
                        helpText: 'Please use format HH:MM'
                    },
                    validators: {
                        starttime: {
                            expression: function ($viewValue, $modelValue, self) {
                                if ($modelValue) {
                                    return (parseInt($modelValue.split(':')[0]) >= 0
                                        && parseInt($modelValue.split(':')[0]) <= 23)
                                        && $modelValue.split(':')[0].length === 2
                                        && (parseInt($modelValue.split(':')[1]) >= 0
                                            && parseInt($modelValue.split(':')[1]) <= 59)
                                        && $modelValue.split(':')[1].length === 2;
                                }
                            }
                        }
                    }
                }
            ];
        }

        function setUpForm() {
            self.user_settings_form = [
                {
                    className: 'flex-33',
                    type: 'select',
                    key: 'DEFAULT_USER_TEAM',
                    templateOptions: {
                        label: 'Team',
                        helpText: 'This team will work as your default team.',
                        labelProp: 'team_name',
                        valueProp: 'team_slug',
                        options: self.all_teams
                    }
                },
                {
                    className: 'flex-33',
                    key: 'DEFAULT_USER_APP',
                    type: 'select',
                    templateOptions: {
                        label: 'Default app',
                        helpText: 'You\'ll be redirected to selected app on login rather than dashboard.',
                        labelProp: 'app_title',
                        valueProp: 'app_key',
                        options: self.apps
                    }
                },
                {
                    className: 'flex-33',
                    type: 'select',
                    key: 'DEFAULT_USER_LANGUAGE',
                    templateOptions: {
                        label: 'Language preference',
                        helpText: 'Advisories will be displayed in this language.',
                        labelProp: 'name',
                        valueProp: 'value',
                        options: []
                    },
                    controller: function ($scope) {
                        if ($scope.to.options.length === 0) {
                            function fetchCommunicationLanguages(pageNo) {
                                CommunicationLanguageFactory.query({page: pageNo}, function (response) {
                                    $scope.to.options = $scope.to.options.concat(response.data);
                                    if (response.next) {
                                        fetchCommunicationLanguages(pageNo + 1);
                                    }
                                });
                            }

                            fetchCommunicationLanguages(1);
                        }
                    }
                },
                {
                    className: 'flex-33',
                    type: 'select',
                    key: 'DEFAULT_USER_READABLE_DATE_FORMAT',
                    templateOptions: {
                        label: 'Readable date format',
                        helpText: 'All dates will be displayed in this format.',
                        labelProp: 'name',
                        valueProp: 'value',
                        options: [
                            {name: 'DD MMM, YYYY', value: '%d %b, %Y'},
                            {name: 'MMM DD, YYYY', value: '%b %d, %Y'}
                        ]
                    }
                },
                {
                    className: 'flex-33',
                    type: 'select',
                    key: 'DEFAULT_USER_READABLE_TIME_FORMAT',
                    templateOptions: {
                        label: 'Readable time format',
                        helpText: 'All times will be displayed in this format.',
                        labelProp: 'name',
                        valueProp: 'value',
                        options: [
                            {name: 'HH:mm', value: '%H:%M'},
                            {name: 'HH:mm:ss', value: '%H:%M:%S'}
                        ]
                    }
                },
                {
                    className: 'flex-33',
                    type: 'select',
                    key: 'DEFAULT_USER_READABLE_DATE_TIME_FORMAT',
                    templateOptions: {
                        label: 'Readable date time format',
                        helpText: 'All datetimes will be displayed in this format.',
                        labelProp: 'name',
                        valueProp: 'value',
                        options: [
                            {name: 'DD MMM, YYYY HH:mm', value: '%d %b, %Y %H:%M'},
                            {name: 'MMM DD, YYYY HH:mm', value: '%b %d, %Y %H:%M'}
                        ]
                    }
                },
                {
                    className: 'flex-33',
                    type: 'select',
                    key: 'DEFAULT_USER_FARM',
                    templateOptions: {
                        label: 'Farm',
                        helpText: 'The map will default to this farm',
                        labelProp: 'name',
                        valueProp: 'slug',
                        options: []
                    },
                    controller: function ($scope) {
                        $scope.$watch('$parent.model.DEFAULT_USER_TEAM', (newVal, oldVal) => {
                            if (newVal) {
                                fetchAvailableFarms().then(function(farms) {
                                   $scope.to.options = [{'slug':null}].concat(farms);
                                });
                            }
                            if (newVal !== oldVal) {
                                $scope.$parent.model.DEFAULT_USER_FARM = null;
                            }
                        });
                    }
                },
                {
                    className: 'flex-33',
                    type: 'select',
                    key: 'DEFAULT_USER_FIELD',
                    templateOptions: {
                        label: 'Field',
                        helpText: 'The map will default to this field',
                        labelProp: 'name',
                        valueProp: 'slug',
                        options: []
                    },
                    controller: function ($scope) {
                        $scope.$watch('$parent.model.DEFAULT_USER_FARM', (newVal, oldVal) => {
                            if (newVal) {
                                fetchAvailableFarms().then(function(farms) {
                                    const farm = _.findWhere(farms, {slug:newVal});
                                    if (farm) {
                                        $scope.to.options = [{'slug':null}].concat(farm.fields);
                                    }
                                });
                            }
                            if (newVal !== oldVal) {
                                $scope.$parent.model.DEFAULT_USER_FIELD = null;
                            }
                        });
                    }
                },
                {
                    className: 'flex-33',
                    type: 'select',
                    key: 'DEFAULT_GROWING_SEASON',
                    templateOptions: {
                        label: 'Growing season',
                        helpText: 'This will be set your default growing season.',
                        labelProp: 'name',
                        valueProp: 'value',
                        options: self.planting_season_list
                    }
                }
            ];
        }

        self.cancel = function () {
            $mdDialog.cancel();
        };
    }
});
