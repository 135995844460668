angular.module("TrialApp").config(function ($mdDateLocaleProvider, $translateProvider) {

    var translateENGFactory = function () {
        "use strict";
        var TranslateHelpers = {
            getTranslateDict: function (singname, pluname) {
                return {
                    card: {
                        title: singname
                    },
                    tableCard: {
                        title: pluname
                    },
                    form: {
                        title: singname
                    },
                    edit: {
                        title: "Edit " + singname
                    },
                    report: {
                        title: singname + " Summary"
                    },
                    message: this.getMessageDict(singname)
                };
            },

            getMessageDict: function (arg) {
                return {
                    save: {
                        success: "Saved successfully.",
                        error: "Failed to save",
                        info: "Saving " + arg + "... "
                    },
                    update: {
                        success: "Updated successfully.",
                        error: "Failed to update " + arg,
                        info: "Updating " + arg + "... "
                    },
                    remove: {
                        warning: "Are you sure you want to delete " + arg + " :",
                        success: arg + " has been deleted successfully.",
                        error: arg + " has not been deleted.",
                        info: "Deleting " + arg + "... "
                    },
                    duplicate: {
                        success: arg + " has been created successfully.",
                        error: arg + " has not been created.",
                        info: "Creating " + arg + "... "
                    }
                };
            }
        };

        return {
            commonEN: function () {
                return {
                    sortBy: "Sort by ",
                    filterBy: "Filter by ",
                    action: "Action",
                    yes: "Yes",
                    no: "No",
                    areYouSure: "Are you sure ?",
                    name: "Name",
                    team_name: "Team Name",
                    created_at: "Created At",
                    location: "Trial Centre(s)",
                    api_retrieve_failed: "Failed to retrieve data, please refresh the page or contact support team.",
                    api_save_failed: "Failed to save data, please refresh the page or contact support team.",
                };
            },

            reportEN: function () {
                var dict = TranslateHelpers.getTranslateDict('Report', 'Reports');
                dict.title = "Title";
                dict.article_type = "Article Type";
                dict.content_code = "Content Code";
                dict.trial_code = 'Trial Code';
                dict.status = 'Status';
                dict.publish_date = "Publish Date";
                return dict;
            },
        };
    };


    var TranslateENGFactory = translateENGFactory();
    $translateProvider.translations('en', {
        report: TranslateENGFactory.reportEN(),
        common: TranslateENGFactory.commonEN()
    });
});
