angular.module("TeamsApp").controller("UsersInTeamController", ["$scope", "$rootScope", "UsersInTeamFactory", "$mdDialog", "ProfileFactory",
    function ($scope, $rootScope, UsersInTeamFactory, $mdDialog, ProfileFactory) {

        $scope.permissions = permissions;
        $scope.currentPage = 1;
        $scope.currentStatsPage = 1;
        $scope.tenant_id = tenant_id;
        $scope.pageSize = 20;

        $scope.kycImages = {
            'Not Started': require("../../../../../img/kyc_not_started.svg"),
            'Incomplete': require("../../../../../img/kyc_incomplete.svg"),
            'Completed': require("../../../../../img/kyc_complete.svg")
        };

        ProfileFactory.get(function (result) {
            $scope.user_profile = result;
        });

        function range(start, stop, step) {
            if (typeof stop == 'undefined') {
                stop = start;
                start = 1;
            }
            if (typeof step == 'undefined') {
                step = 1;
            }
            if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
                return [];
            }
            var result = [];
            for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
                result.push(i);
            }
            return result;
        }

        $scope.totalTeamPages = [];
        function getTeamStats() {
            UsersInTeamFactory.query({page_size: $scope.pageSize}, function (result) {
                $scope.totalTeamPages = range(Math.ceil((result.count / $scope.pageSize)) + 1);
                $scope.teams = result.results;
                sessionStorage.setItem("team_statistics_current_page", 1);
            });
        }

        getTeamStats();

        $scope.getMoreUserStatus = function(pageNum, query) {
            UsersInTeamFactory.query({filter: query, page: pageNum}, function (result) {
                $scope.teams = result.results;
            });
        };
        $scope.nextPage = function(query) {
            var page = parseInt(sessionStorage.getItem("team_statistics_current_page")) + 1;
            if ($scope.totalTeamPages.indexOf(page) != -1) {
                $scope.getMoreUserStatus(page, query);
                sessionStorage.setItem("team_statistics_current_page", page);
            }
        };
        $scope.checkAbilityForNextPage = function () {
            var page = parseInt(sessionStorage.getItem("team_statistics_current_page")) + 1;
            return $scope.totalTeamPages.indexOf(page) != -1;
        };
        $scope.checkAbilityForPrevPage = function () {
            var page = parseInt(sessionStorage.getItem("team_statistics_current_page")) - 1;
            return $scope.totalTeamPages.indexOf(page) != -1;
        };
        $scope.prevPage = function(query) {
            var page = parseInt(sessionStorage.getItem("team_statistics_current_page")) - 1;
            if ($scope.totalTeamPages.indexOf(page) != -1) {
                $scope.getMoreUserStatus(page, query);
                sessionStorage.setItem("team_statistics_current_page", page);
            }
        };
        $scope.seachTeam = function (query) {
            UsersInTeamFactory.query({filter: query}, function (result) {
                $scope.totalTeamPages = range(Math.ceil(result.count / $scope.pageSize) + 1);
                $scope.teams = result.results;
                sessionStorage.setItem("team_stats_current_page", 1);
            });
            if (query["user_full_name"] == '' && query["email"] == '' && query["team"] == '' && query["mobile"] == ''){
                getTeamStats();
            }
        };

        $scope.activateDeactivateUser = function (index) {
            var username = $scope.teams[index].username;
            var status = !$scope.teams[index].is_active;
            if (status) {
                var deactivateConfirm = $mdDialog.confirm()
                    .title('Deactivate user')
                    .textContent('Are you sure you want to deactivate the user?')
                    .ariaLabel('Deactivate user')
                    .ok('Deactivate')
                    .cancel('Cancel');
                $mdDialog.show(deactivateConfirm).then(function () {
                    window.location = "/profiles/viewteam/" + username + "/deactivate_user/";
                }, function () {
                    $scope.teams[index].is_active = !$scope.teams[index].is_active;
                });
            }
            else {
                var activateConfirm = $mdDialog.confirm()
                    .title('Activate user')
                    .textContent('Are you sure you want to activate the user?')
                    .ariaLabel('Activate user')
                    .ok('Activate')
                    .cancel('Cancel');
                $mdDialog.show(activateConfirm).then(function () {
                    window.location = "/profiles/viewteam/" + username + "/activate_user/";
                }, function () {
                    $scope.teams[index].is_active = !$scope.teams[index].is_active;
                });
            }
        }

    }
]);
